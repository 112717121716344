/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import { SetStateAction, useEffect, useState, Dispatch, useContext } from "react";
import { Spin } from 'antd';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import '../../../src/Components/Modal/modal.css';
import { RootStoreContext } from 'stores/rootStore'


import envelopeImage from './envelope.png'
const API_URL = process.env.REACT_APP_API_URL;

const DOCUSIGN_URL = process.env.REACT_APP_DOCUSIGN_BACKEND_URL;


interface IProps {
	
	inputEnvelopeID:string
	setInputEnvelopeID: Dispatch<SetStateAction<string>>
	verificationResult:string
	setVerificationResult:Dispatch<SetStateAction<string>>
	verificationSuccess:boolean
	setVerificationSuccess:Dispatch<SetStateAction<boolean>>
	setVerificationDocusign:Dispatch<SetStateAction<boolean>>
}

const popupDisplayerror = () => {
	Swal.fire({
		title: '<span style="color: #13C296;">Importante</span>',
		icon: 'info',
		html:
			'<b>Su contrato no existe o escribio un numero de contrato erroneo, contactanos al correo retainer.agreement@immilandcanada.com para más información</p>',
		showCloseButton: true,
		showCancelButton: false,
		focusConfirm: false,
		confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			  },
			  buttonsStyling: false,
	});
};

const popupDisplayInfo = () => {
	Swal.fire({
		title: '<span style="color: #13C296;">¿Cómo ver mi número de contrato?</span>',
		icon: 'info',
		html:
			`<a href="https://ibb.co/XSNgxqT"><img src=${envelopeImage} alt="envelope" border="0" max-width: 100% /></a>`,
		showCloseButton: true,
		showCancelButton: false,
		focusConfirm: false,
		confirmButtonText: 'Entendido',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			  },
			  buttonsStyling: false,
	});
};
const DocusignInput: React.FC<IProps> = ({ setVerificationDocusign,inputEnvelopeID,setInputEnvelopeID,verificationResult,setVerificationResult,verificationSuccess,setVerificationSuccess }) => {


	const [loading, setLoading] = useState(false);

	const handleCheckEnvelopeID = () => {
		if (!inputEnvelopeID) {
			setVerificationResult("Ingrese un envelopeID válido.");
			return;
		}
		setLoading(true);
		axios
			.get(`${DOCUSIGN_URL}/getPdf/${inputEnvelopeID}`)
			.then((response) => {
				if (response.data && response.data.errorCode) {
					popupDisplayerror();
					setVerificationResult("Su contrato no existe o escribio un numero de contrato erroneo.");
					setVerificationSuccess(false);
					setVerificationDocusign(false)
					
				} else {
					setVerificationResult("Comprobacion exitosa. ");
					setVerificationSuccess(true);
					setVerificationDocusign(true)
				}

			})
			.catch((error) => {
				setVerificationResult("Error en la verificacion.");
				setVerificationSuccess(false);
				setVerificationDocusign(false)
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const [showTooltip, setShowTooltip] = useState(false);
	return (
		<>
			<label className="titleverify label-info">
			<p>Ingrese el ID de su contrato previo y obtenga un  <i
          onClick={popupDisplayInfo}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          style={{ fontSize: '0.7rem', fontWeight: 'bold', color:'#22AD5C', cursor: 'pointer', position: 'relative' }}
        >
          30% de descuento.
          {showTooltip && (
            <span style={{
              position: 'absolute',
              top: '-20px',
              left: '0',
              backgroundColor: '#22AD5C',
              color: '#fff',
              padding: '5px',
              borderRadius: '3px',
              fontSize: '0.7rem',
			  width:'5rem',
            }}>
             click para más información!
            </span>
          )}
        </i>  </p>
				
			</label>

			
			<div className="button-container" style={{marginTop:'7px'}}>
			<input className='custom-input' type="text" value={inputEnvelopeID} onChange={(e) => {
					setInputEnvelopeID(e.target.value)
				}}	
				/>
				<button className="buttonverify"  style={{padding:'0px 24px',height:'46px',marginLeft:'12px'}} type="button" onClick={handleCheckEnvelopeID}>
					<p style={{color:'#0B76B7'}}>Verificar</p>
				</button>
				{loading && <Spin size="large" style={{ marginLeft: '10px',width: '10px',height: '10px',color: 'red' }} />}
			</div>

			{/* {verificationSuccess && <p> Comprobacion exitosa  </p>}
			{verificationSuccess === false && <p> Su contrato no existe o escribio un numero de contrato erroneo, contactanos al correo
			</p>} */}

			{<p>{verificationResult} {verificationResult==='Comprobacion exitosa. '&& <CheckCircleOutlined style={{ color: 'green', marginLeft: '10px' }} />  }</p>}
	
			</>
	);
}

export default DocusignInput;
