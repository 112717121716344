
import {
	Box, Button
} from 'Components'
import { FamilyTypes, initialValues } from '../../types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/index.css'
import OutsideCanada from './OutsideCanada'
import Visitor from './Visitor'
import Study from './Study'
import Work from './Work'
import InsideCanada from './InsideCanada'
import PersonalData from './PersonalData'
import PersonalDataWork from './PersonalDataWork'
import PersonalDataInsideCanada from './PersonalDataInsideCanada'
import PersonalDataPGWP from './PersonalDataPGWP'
import PermanentRecidence from './PermanetRecidence'
import PersonalDataCoop from './PersonalDataCoop'
import PersonalDataPermanentRecidence from './PersonalDataPermanentResidence'
import PersonalDataWorkInsideCanada from './PersonalDataWorkInsideCanada'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
interface IProps {
	closeModal: () => void
	edit: boolean
	setEdit: React.Dispatch<React.SetStateAction<boolean>>
}
const VisaQuestions: React.FC<IProps> = ({ closeModal, edit, setEdit }) => {
	const rootStore = useContext(RootStoreContext)
	const {
		principalUsed,
		currentMember,
		handleChangeFamilyMember,
		familyMembers,
		setFamilyMember,
		setCurrentMember,
	} = rootStore.familyStore
	const DOCUSIGN_BACK_URL = process.env.REACT_APP_DOCUSIGN_BACKEND_URL
	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [emailForUploaders, setEmailForUploaders] = React.useState('')
	const { step: stepLine } = rootStore.formBuilderStore
	const [step, setStep] = React.useState<number>(1)
	const displayedStep = stepLine === 1 ? 2 : stepLine;

	useEffect(() => { }, [currentMember])
	useEffect(() => {
		if (currentMember.age && currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])
	useEffect(() => { }, [open])
	useEffect(() => {
		if (step === 1 && edit === true) {
			setEdit(false)
		}
	}, [step])

	useEffect(() => { }, [principalUsed])
	useEffect(() => { }, [emailForUploaders])

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
		//const newFamily = {...family}
		const newFamily = Object.assign(currentMember, { [target]: value })
		handleChangeFamilyMember(newFamily)
	}


	const [currentStep, setCurrentStep] = useState(1);
	const [selectedPurpose, setSelectedPurpose] = useState("");
	const [isnextbuttonDisabled, setIsnextbuttonDisabled] = useState(true);
	const [showInput, setShowInput] = useState(false);
	const [next, setNext] = useState(false)
	const [inputValue, setInputValue] = useState<number | string>('');
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);

	const handleOptionChange = (value: React.SetStateAction<string>) => {
		setSelectedPurpose(value);
		setIsnextbuttonDisabled(!value || !isCheckboxChecked || !selectedDate);
	};
	
	const handleDateChange = (date: Date | null) => {
		setSelectedDate(date); // Update state with the selected date
	
		// Format the date in yyyy-MM-dd if date is not null
		const formattedDate = date ? date.toISOString().split('T')[0] : '';
	
		// Update the global or relevant state with ExpiredPermitDate
		handleChangeRadio('ExpiredPermitDate', formattedDate);
		setIsnextbuttonDisabled(!selectedPurpose || !isCheckboxChecked || !date);
	};
	
	const handleChangeRadio = (fieldName: any, value: any) => {
		rootStore.familyStore.currentMember[fieldName] = value;
		setIsnextbuttonDisabled(!value || !isCheckboxChecked || !selectedDate);
	};
	
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
	
	const handleContinuarClick = () => {
		// Ensure that ExpiredPermitDate is correctly updated before proceeding
		if (!isnextbuttonDisabled && isCheckboxChecked) {
			handleChangeRadio('typeOfApplication', selectedPurpose);
			switch (selectedPurpose) {
				case "Expired Permit":
					handleChangeRadio('ExpiredPermit', "Expired Permit");
					handleChangeRadio('ExpiredPermitDate', currentMember.ExpiredPermitDate);
					break;
				case "Not Expired Permit":
					handleChangeRadio('ExpiredPermit', "Not Expired Permit");
					break;
				case "Expired Permit90":
					handleChangeRadio('ExpiredPermit90', "Expired Permit90");
					break;
				default:
					break;
			}
			setStep(2);
		}
	};
	
	const handleCheckboxChange = (e: { target: any }) => {
		const checkbox = e.target;
		setIsCheckboxChecked(checkbox.checked);
		setIsnextbuttonDisabled(!checkbox.checked || !selectedPurpose || !selectedDate);
		if (checkbox.checked) {
			checkbox.classList.add('checked');
		} else {
			checkbox.classList.remove('checked');
		}
	};
	return (
		<div className='my-4 h-100'>
			<Box>
				<>
				
				<div className='flex flex-col' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' , width:'100%', gap:'30px' }} >
					<div className='step-two-container'>
						<div className="stepper-container" style={{ display: 'flex', justifyContent: 'space-between', width:'80%' }}>
							{/* Step 1 */}
							<div className="step">
								<div className="circle">1</div>
								<p className="step-label">PROPÓSITO</p>
							</div>

							{/* Step 2 */}
							<div className="step selected">
								<div className="circle">2</div>
								<p className="step-label">DATOS MIGRATORIOS</p>
							</div>

							{/* Step 3 */}
							<div className="step">
								<div className="circle">3</div>
								<p className="step-label">COTIZACIÓN</p>
							</div>
						</div>
						<div className="progress-line">
							<div className="progress-line-completed">
							<div className="dot completed-dot"></div>
							<div className="checkmark-icon">
								<div className="checkmark"></div>
							</div>
						</div>
						<div className="progress-line-completed2">
							<div className="dot completed-dot1"></div>
						</div>
						
						<div className="dot-uncompleted2"></div>
					</div>
				</div>
			</div>

					<form className='maincontainer' id='fromEl'>
						<div className='flex flex-wrap -mx-3 mb-6'>
							{step === 1 &&
								currentMember.type === FamilyTypes.principal &&
								edit === false ?
								<>
									{/* start page 1 */}
									<div className='container-aplication'>
										<div className='titledivquestions' >
											<h1>¿Desde dónde aplicará?</h1>
										</div>
										<div className='containerbackandnextbuttons flex flex-wrap'>
											<div>
												<button className='rcorners2'
													onClick={() => {
														setStep(98)
														handleChange('inCanada', "si")
														handleChange('countryOfCurrentResidency', 'canada')
													}}>
													<div className='button-inside'>
														<img src="/icons/image-20.png" alt="Arrow Icon" />
														<p>Estoy
															<span style={{ color: '#C7B299', fontWeight:'bold' }}>  dentro  </span>
															de Canadá</p>
													</div>
												</button>
											</div>
											<div>
												<button className='rcorners2' onClick={() => {
													setStep(2)
													handleChange('inCanada', "no")
												}}>
													<div className='button-inside'>
														<img src="/icons/image-21.png"
															alt="Arrow Icon" />
														<p>Estoy
															<span style={{ color: '#C7B299', fontWeight:'bold' }}>  fuera  </span>
															de Canadá</p>
													</div>
												</button>
											</div>
										</div>
									</div>
									{/* end page 1 */}
								</> : null}

							{step === 98 &&
								currentMember.type === FamilyTypes.principal &&
								edit === false ?
								<>
									
									<div className='mt-5 mb-5 pl-5 pr-5' >
										<div className='flex flex-col' >

	    <div className='container-aplication'>
      
        <div className='titledivquestions-expired' >
          <a className='responsive-title-expired' >¿Cual es la situación de su permiso o estatus actual?</a>
        </div>
        <div style={{ backgroundColor: '#DFE4EA', width: '100%', height: '1px', marginBottom: '2rem', marginLeft: '2rem' }}></div>
        <div className='container-radio-select flex flex-wrap gap-8'>
          <div className='container-wrapper-expired' style={{ display: 'flex', flexDirection: 'column', gap: '1rem', flexWrap: 'wrap' }}>
            <div>
              <div className="radio-selector-expired">
                <input
                  type="radio"
                  value="Expired Permit"
                  checked={selectedPurpose === "Expired Permit"}
                  onChange={() => {
                    handleOptionChange("Expired Permit")
                  }}
                />
                <p className='text_expired_resposive' style={{ color: '#111928',  fontWeight: '700', fontSize: '16px' }}>Está vigente y caduca dentro de los próximos 30 días.</p>
              </div>
            </div>
			<div>
              <div className="radio-selector-expired">
                <input
                  type="radio"
                  value="Expired Permit90"
                  checked={selectedPurpose === "Expired Permit90"}
                  onChange={() => {
                    handleOptionChange("Expired Permit90")
                  }}
                />
                <p className='text_expired_resposive' style={{ color: '#111928',  fontWeight: '700', fontSize: '16px' }}>Caducó en los últimos 30 o 60 días.</p>
              </div>
            </div>
            <div>
              <div className="radio-selector-expired">
                <input
                  type="radio"
                  value="Not Expired Permit"
                  checked={selectedPurpose === "Not Expired Permit"}
                  onChange={() => {
                    handleOptionChange("Not Expired Permit")
                  }}
                />
                <p className='text_expired_resposive' style={{ color: '#111928',  fontWeight: '700', fontSize: '16px' }}>Está vigente y continuará válido durante al menos los próximos 31 días.</p>
              </div>
            </div>
        
          </div>
		  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', width: '100%' }}>
				<div className='input-container' style={{display:'flex',padding:'16px' ,boxSizing:'border-box' , alignItems:'center', gap:'10px', marginRight:'26px', backgroundColor:'#C7B299', borderRadius:'20px'}}>
					<p style={{color:'black', marginBottom:'8px', fontSize:'14px'}}>Indique la fecha de vencimiento de su permiso o estatus:</p>
					<DatePicker
						className='custom-input-datepicker-expired'
						id="expirationInput"
						selected={selectedDate}
						onChange={handleDateChange}
						dateFormat="yyyy-MM-dd"
						placeholderText=""
					/>
				</div>
				{selectedPurpose !== "Not Expired Permit" && (
				    <div className='card-1-expired' style={{ width: '695px', height: '85px', left: '2.5px', padding: '16px 35px 16px 35px', gap: '10px', borderRadius: '8px', border: '0px 0px 0px 6px', opacity: '0px', backgroundColor: '#FFFBEB', display: 'flex', alignItems: 'center', borderLeftColor: '#FBBF24', borderLeftWidth: '5px' }}>
				      <div>
				        <img src="/icons/yellowicon.svg" alt="Home" style={{ width: '34px', height: '34px' }} />
				      </div>
				      <div style={{ marginLeft: '10px' }}>
				        <p style={{  fontSize: '18px', fontWeight: '700', lineHeight: '26px', textAlign: 'left', color: '#9D5425' }}>IMPORTANTE</p>
				        <p style={{  fontSize: '14px', fontWeight: '700', lineHeight: '26px', textAlign: 'left', color: '#9D5425' }}>
				          {selectedPurpose === "Expired Permit90"
				            ? <p style={{  fontSize: '14px', fontWeight: '700', lineHeight: '26px', textAlign: 'left', color: '#9D5425' }}><strong>Se añadirá un cargo de $1029 CAD</strong> por concepto de procesamiento urgente y restauración de estatus, <strong>algunas tarifas no son aplicables para PGWP, CO-OP o Residencia permanente.</strong></p>
				            :<p style={{  fontSize: '14px', fontWeight: '700', lineHeight: '26px', textAlign: 'left', color: '#9D5425' }}><strong>Se añadirá un cargo de $400 CAD</strong> por concepto de procesamiento urgente. </p>
				          }
				        </p>
				      </div>
				    </div>
                )}

            <div className='card-1-expired' style={{ width: '700px', height: '68px', top: '97px', gap: '0px', borderRadius: '10px 0px 0px 0px', opacity: '0px', backgroundColor: '#F9FAFB', display: 'flex', alignItems: 'center', padding: '16px 35px 16px 35px' }}>
              <input
                type="checkbox"
                className="checkbox-input"
                onChange={handleCheckboxChange}
              />
              <p style={{  fontSize: '16px', fontWeight: '400', lineHeight: '24px', textAlign: 'left', color: '#111928' }}>Entiendo y confirmo lo informado en el texto anterior.</p>
            </div>
          </div>
        </div>
        <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '30rem', justifyContent: 'center', marginTop: '5rem' }}>
          <button style={{ width: '14rem' }} className='backbutton-disc2' onClick={() => {
            setCurrentStep(1);
            scrollTo(0, 0);
          }}>Atras
          </button>

		  <button onClick={handleContinuarClick} className={`startbutton-disc2 ${isnextbuttonDisabled ? 'button-disabled' : ''}`} disabled={isnextbuttonDisabled}>
            Continuar
          </button>
        
      </div>
    </div>
										</div>
									</div>

								
								</> : null}

							{/*OUTSIDE CANADA*/}

							{currentMember.inCanada === "no" ? <>
								{step === 2 && <>
									{/* start page 2 outside canada */}
									<div>
										<OutsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
									</div>
									{/* end page 2 outside canada*/}
								</>}
								{currentMember.purposeVisit === "Visitar o transito" ? <>
									<div>
										<Visitor closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
									</div>
								</> : null}
								{currentMember.purposeVisit === "Estudios" ? <>
									<div>
										<Study closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
									</div>
								</> : null}
								{currentMember.purposeVisit === "Trabajo" ? <>
									<div>
										<Work closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
									</div>
								</> : null}
								{currentMember.purposeVisit === "Residencia Permanente" ? <>
									<div>
										<PermanentRecidence closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
									</div>
								</> : null}
							</> : null}

							{/* INSIDE CANADA */}

							{currentMember.inCanada === "si" ? <>
								{step === 2 ? <>
									{/* start page 2 */}
									<div>
										<InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
									</div>
									{/* end page 2 */}
								</> : null}
								{/* start page 3 */}
								{step === 3 &&
									currentMember.purposeVisit === 'Residencia Permanente' ?
									<>
										{/* start page 3 */}
										<div>
											<PermanentRecidence closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
										</div>
									</> : null}
								{step === 3 &&
									currentMember.purposeVisit !== 'Residencia Permanente' ?
									<>
										{/* start page 3 */}
										<div>
											<InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
										</div>
									</> : null}
								{step === 12 &&
									currentMember.purposeVisit === 'PGWP' ?
									<>
										{/* start page 4 */}
										<div>
											<PersonalDataPGWP closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
										</div>
									</> : null}
								{step === 19 ? <PersonalDataWorkInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
									: null}
								{step === 16 &&
									currentMember.purposeVisit === 'Co-op' ?
									<>
										{/* start page 4 */}
										<div>
											<PersonalDataCoop closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
										</div>
									</> : null}
								{/* end page 3 */}
								{currentMember.purposeVisit !== 'Residencia Permanente' ? <>
									{step === 4 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 5 && <PersonalDataInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 6 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 9 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 10 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 11 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 13 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 14 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 15 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 16 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 17 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 18 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 20 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 21 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									{step === 99 && <InsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
								</> :
									<>
										{step === 4 && <PermanentRecidence closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
										{step === 5 && <PermanentRecidence closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
										{step === 6 && <PermanentRecidence closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
										{step === 7 && <PermanentRecidence closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
										{step === 8 && <PermanentRecidence closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
									</>}
							</> : null}

							{/*visa de turismo fuera de canada*/}
							{edit === true &&
								currentMember.type === FamilyTypes.principal &&
								familyMembers[0].purposeVisit !== 'Trabajo' &&
								familyMembers[0].inCanada === "no" &&
								familyMembers[0].purposeVisit === 'Visitar o transito' &&
								<PersonalData closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{/*visa de estudios fuera de canada*/}
							{edit === true &&
								currentMember.type === FamilyTypes.principal &&
								familyMembers[0].purposeVisit !== 'Trabajo' &&
								familyMembers[0].purposeVisit === 'Estudios' &&
								familyMembers[0].inCanada === "no" &&
								<PersonalData closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{/*conyugue and children para visa de turismo y estudios fuera de canada  */}
							{familyMembers[0].wantsAddConyugue === "si" &&
								familyMembers[0].purposeVisit !== 'Trabajo' &&
								principalUsed()?.purposeVisit !== 'Residencia Permanente' &&
								currentMember.type === FamilyTypes.conyuge && familyMembers[0].inCanada === "no" &&
								<PersonalData closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{Number(familyMembers[0].howManyDependantChild) > 0 &&
								currentMember.type === FamilyTypes.children &&
								familyMembers[0].purposeVisit !== 'Trabajo' &&
								principalUsed()?.purposeVisit !== 'Residencia Permanente' &&
								familyMembers[0].inCanada === "no" &&
								<PersonalData closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{/*Miembtros para work  */}
							{edit === true &&
								familyMembers[0].purposeVisit === 'Trabajo' &&
								<PersonalDataWork closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{currentMember.type === FamilyTypes.conyuge &&
								familyMembers[0].purposeVisit === 'Trabajo' &&
								<PersonalDataWork closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{currentMember.type === FamilyTypes.children &&
								familyMembers[0].purposeVisit === 'Trabajo' &&
								<PersonalDataWork closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{/*Miembtros para PGWP  */}
							{edit === true &&
								familyMembers[0].inCanada === 'si' &&
								familyMembers[0].typeOfApplication === 'PGWP' &&
								currentMember.type === FamilyTypes.principal &&
								<PersonalDataPGWP closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{familyMembers[0].inCanada === 'si' &&
								familyMembers[0].typeOfApplication === 'PGWP' &&
								currentMember.type === FamilyTypes.children &&
								<PersonalDataPGWP closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{familyMembers[0].inCanada === 'si' &&
								familyMembers[0].typeOfApplication === 'PGWP' &&
								currentMember.type === FamilyTypes.conyuge &&
								<PersonalDataPGWP closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{/* Miembtros para COOP */}
							{edit === true &&
								familyMembers[0].inCanada === 'si' &&
								familyMembers[0].typeOfApplication === 'Co-op work permit' &&
								<PersonalDataCoop closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{/* Miembtros para Permanent residence */}
							{currentMember.type === FamilyTypes.conyuge &&
								principalUsed()?.purposeVisit === 'Residencia Permanente' &&
								<PersonalDataPermanentRecidence closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{currentMember.type === FamilyTypes.children &&
								principalUsed()?.purposeVisit === 'Residencia Permanente' &&
								<PersonalDataPermanentRecidence closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

							{edit === true &&
								currentMember.type === FamilyTypes.principal &&
								currentMember.purposeVisit === 'Residencia Permanente' &&
								<PersonalDataPermanentRecidence closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
						</div>

						{/* personal data inside canada */}
						{edit === true &&
							familyMembers[0]?.inCanada === 'si' &&
							familyMembers[0].typeOfApplication === 'Visitor Visa Extension' &&
							currentMember.type === FamilyTypes.principal &&
							<PersonalDataInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

						{familyMembers[0]?.inCanada === 'si' &&
							familyMembers[0]?.typeOfApplication === 'Visitor Visa Extension' &&
							currentMember.type === FamilyTypes.children &&
							<PersonalDataInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

						{familyMembers[0]?.inCanada === 'si' &&
							familyMembers[0]?.typeOfApplication === 'Visitor Visa Extension' &&
							currentMember.type === FamilyTypes.conyuge &&
							<PersonalDataInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

						{edit === true &&
							familyMembers[0]?.inCanada === 'si' &&
							familyMembers[0].typeOfApplication === 'Study Visa Extension' &&
							currentMember.type === FamilyTypes.principal &&
							<PersonalDataInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

						{familyMembers[0]?.inCanada === 'si' &&
							familyMembers[0]?.typeOfApplication === 'Study Visa Extension' &&
							currentMember.type === FamilyTypes.children &&
							<PersonalDataInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

						{familyMembers[0]?.inCanada === 'si' &&
							familyMembers[0]?.typeOfApplication === 'Study Visa Extension' &&
							currentMember.type === FamilyTypes.conyuge &&
							<PersonalDataInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

						{edit === true &&
							familyMembers[0]?.inCanada === 'si' &&
							familyMembers[0].typeOfApplication === 'Visa Renovation' &&
							currentMember.type === FamilyTypes.principal &&
							<PersonalDataInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

						{familyMembers[0]?.inCanada === 'si' &&
							familyMembers[0]?.typeOfApplication === 'Visa Renovation' &&
							currentMember.type === FamilyTypes.children &&
							<PersonalDataInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

						{familyMembers[0]?.inCanada === 'si' &&
							familyMembers[0]?.typeOfApplication === 'Visa Renovation' &&
							currentMember.type === FamilyTypes.conyuge &&
							<PersonalDataInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

						{familyMembers[0]?.inCanada === 'si' &&
							familyMembers[0].typeOfApplication === 'Work Visa Extension' &&
							currentMember.type === FamilyTypes.children &&
							<PersonalDataWorkInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}

						{familyMembers[0]?.inCanada === 'si' &&
							familyMembers[0].typeOfApplication === 'Work Visa Extension' &&
							currentMember.type === FamilyTypes.conyuge &&
							<PersonalDataWorkInsideCanada closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
					</form>
				</>
				<footer className='footer-style' >
					<div className='footer-style-continer'>
						<div style={{
							width: '10rem',
							display: 'flex',
							flexDirection: 'column'
						}}>
							<h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>¿Quienes somos?</strong></h3>
							<a href="https://www.immilandcanada.com/quienes-somos">Historia y Valores</a>
							<a href="https://www.immilandcanada.com/equipo-immiland">Nuestro Equipo</a>
							<a href="https://www.immilandcanada.com/testimonios">Testimonios</a>
							<a href="https://www.immilandcanada.com/tipos-de-visa/visa-de-estudio#">Preguntas Frecuentes</a>
							<a href="https://www.immilandcanada.com/terminos-y-condiciones"><strong>Términos y condiciones.</strong></a>
						</div>
						<div style={{
							width: '10rem',
							display: 'flex',
							flexDirection: 'column'
						}} >
							<h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>Servicios</strong></h3>
							<a href="https://www.immilandcanada.com/migracion/inicio">Migración</a>
							<a href="https://www.immilandcanada.com/reclutamiento/inicio">Trabajo</a>
							<a href="https://www.immilandcanada.com/educacion/inicio" >Educacion</a>
							<a href="https://www.immilandcanada.com/traduccion/inicio">Traducciones</a>
							<a href="https://www.immilandcanada.com/establecimiento/inicio">Establecimiento</a>
						</div>
						<div style={{
							width: '10rem',
							display: 'flex',
							flexDirection: 'column'
						}}>
							<div style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>Ubicación</strong></div>
							<div>
								<a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x4cc91bcf50dcd709:0x39dd1ca577308a39?source=g.page.share" >
									<div>2025 Rue Parthenais<br />suit 320<br />Montreal, Quebec,<br />Canadá</div>
								</a>
							</div>
						</div>
						<div style={{
							width: '10rem',
							display: 'flex',
							flexDirection: 'column'
						}}>
							<h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>Blog</strong></h3>
							<div>
								<a href="https://www.immilandcanada.com/post/viajar-fuera-canada-mientras-estudio">
									<div>¿Puedo viajar fuera <br />de Canada mientras estudio?</div>
								</a>
							</div>
						</div>
						<div style={{
							width: '10rem',
							display: 'flex',
							flexDirection: 'column'
						}}>
							<div >
								<h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>¡Sigamos en contacto!</strong></h3>
								<div ><span>Info@immilandcanada.com</span></div>
								<div style={{
									display: 'flex',
									gap: '0.5rem',
									paddingRight: '2rem',
									alignItems: 'center'
								}}>
									<a href="https://www.youtube.com/immiland">
										<img src="https://img.icons8.com/ios-filled/25/ffffff/youtube-play.png" alt="" />
									</a>
									<a href="https://www.instagram.com/immilandcanada/">
										<img src="https://img.icons8.com/windows/25/ffffff/instagram-new.png" alt="" />
									</a>
									<a href="https://www.facebook.com/immilandcanada">
										<img src="https://img.icons8.com/ios-filled/25/ffffff/facebook--v1.png" alt="" />
									</a>
									<a href="https://www.tiktok.com/@immilandcanada">
										<img src="https://img.icons8.com/ios-filled/25/ffffff/tiktok--v1.png" alt="" />
									</a>
									<a href="https://ca.linkedin.com/company/immiland-canada">
										<img src="https://img.icons8.com/ios-glyphs/25/ffffff/linkedin-2--v1.png" alt="" />
									</a>
									<a href="https://twitter.com/immilandcanada">
										<img src="https://img.icons8.com/material/25/ffffff/twitter--v2.png" alt="" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</Box>
		</div >
	)
}
export default VisaQuestions
