/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Select } from 'Components'
import { FamilyTypes } from '../../types'
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { CountrySelector } from 'Components'
import { ETA } from '../../Data/ETACountries'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import Swal from 'sweetalert2'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/index.css'
import { ProvinceSelector } from 'Components/ProvinceSelector'
import '../../../../../Components/Modal/modal.css'
import { Checkbox } from 'antd'

interface IProps {
  closeModal: () => void
  edit: boolean
  step: number
  setStep: Dispatch<SetStateAction<number>>
}
const InsideCanada: React.FC<IProps> = ({ edit, step, setStep }) => {
  const rootStore = useContext(RootStoreContext)
  const {
    principalUsed,
    currentMember,
    handleChangeFamilyMember,
    getVisaTypeFromSurvey,
  } = rootStore.familyStore
  const [birthday, setBirthday] = React.useState(currentMember.birthday)
  const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
  const [etaCountry, setEtaCountry] = React.useState(currentMember.etaCountry)
  const [usVisa, setUSVisa] = React.useState(false)
  const [showEtaImmiland, setShowEtaImmiland] = React.useState(false)
  const [emailForUploaders, setEmailForUploaders] = React.useState('')
  const [displayErrors, setDisplayErrors] = React.useState(false)
  const [selectedButton, setSelectedButton] = useState(null);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const handleButtonClick = (value: any) => {
    setSelectedButton(value);
    handleChange('wantValidationLmiaExemptJobOffer', value);
  };
  useEffect(() => { }, [currentMember])

  useEffect(() => {
    if (currentMember.age > -1) handleChange('valid', true)
    handleChange('age', moment().diff(birthday, 'years', false))
  }, [birthday])

  useEffect(() => {
    validateShowBiometrics()
  }, [currentMember.age, edit])

  useEffect(() => { }, [open])

  useEffect(() => { }, [principalUsed])

  useEffect(() => { }, [emailForUploaders])


  const handleChange = (
    target: string,
    value: string | number | Date | File | boolean | undefined
  ) => {
    const newFamily = Object.assign(currentMember, { [target]: value })
    handleChangeFamilyMember(newFamily)
  }

  const validateEtaCountry = (nationality: string) => {
    const isEtaCountry = ETA.find(
      (etas) => etas.country.toLocaleLowerCase() === nationality.toLocaleLowerCase()
    )
    setEtaCountry(false)
    setShowEtaImmiland(false)
    handleChange('etaCountry', false)
    handleChange('validEta', false)
    if (isEtaCountry) {
      handleChange('etaCountry', true)
      handleChange('validEta', true)
      setEtaCountry(true)
    }
    validateBrazil()
  }

  const validateBrazil = () => {
    if (currentMember.nationality.toLocaleLowerCase() === 'brazil') {
      setUSVisa(true)
      if (currentMember.etaRejection === 'si') {
        handleChange('validEta', false)
        setShowBiometricSelect(true)
        setShowEtaImmiland(false)
      } else {
        if (currentMember.etaRejection === 'no' && currentMember.visaEU === 'no') {
          handleChange('validEta', false)
          setShowBiometricSelect(true)
          setShowEtaImmiland(false)
        } else if (currentMember.etaRejection === 'no' && currentMember.visaEU === 'si') {
          handleChange('validEta', true)
          setShowBiometricSelect(false)
          //  handleChange('hasBiometrics', false)
          setShowEtaImmiland(true)
        }
      }
    } else {
      setUSVisa(false)
    }
  }

  const validateShowBiometrics = () => {
    setShowBiometricSelect(true)
    if (
      currentMember.type === FamilyTypes.principal &&
      currentMember.ageForBiometrics === true &&
      getVisaTypeFromSurvey() === 'visadeestudios'
    ) {
      setShowBiometricSelect(true)
      return
    }
    if (
      !(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
      currentMember.etaCountry === true &&
      currentMember.etaRejection === 'no'
    ) {
      setShowBiometricSelect(false)
      return
    }
    if (currentMember.ageForBiometrics === false) {
      setShowBiometricSelect(false)
      return
    }
    if (currentMember.etaRejection === 'si') {
      if (currentMember.ageForBiometrics === true) {
        setShowBiometricSelect(true)
      }
      return
    }
  }

  const popupDisplayLMIA = () => {
    Swal.fire({
      title: '<span style="color: #13C296;">Importante</span>',
      icon: 'info',
      html:
        '<p>Una evaluación del impacto en el mercado laboral (LMIA) es un documento que puede necesitar un empleador en Canadá antes de contratar a un trabajador extranjero.	<br> Una vez que el empleador obtiene el LMIA el trabajador puede solicitar un permiso de trabajo cerrado.</p>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
      },
      buttonsStyling: false,
    })
  }

  const popupVisitorExtention = () => {
    Swal.fire({
      title: '<span style="color: #13C296;">Importante</span>',
      icon: 'info',
      html:
        '<p style=" text-align: left">Debido a la política temporal vigente hasta el 28 de febrero de 2025, los extranjeros en Canadá con estatus de visitante que tengan una oferta de trabajo valida pueden aplicar a un permiso de trabajo sin tener que salir del pais.</p>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
      },
      buttonsStyling: false,
    })
  }

  const popupNoPayForValidation = () => {
    const checkboxText = 'Aceptar';
    Swal.fire({
      title: '<span style="color: #13C296;">Importante</span>',
      icon: 'info',
      html:
        '<p style=" text-align: left">Por favor solicite a su empleador llenar el siguiente formulario: </p> <br>' +
        '<a style=" text-align: left; color: #bf3c3c" target="_blank" href="https://en.immilandcanada.com/reclutamiento/lmia"><b>https://en.immilandcanada.com/reclutamiento/lmia</b></a><br></br>' +
        '<p>para proceder a realizar el contrato de prestación de servicios para la solicitud de LMIA-exemption.</p>' +
        '<p>Recuerde que hasta que no se reciba la aprobación del <b>código de LMIA-exemption</b>, no es posible aplicar a su permiso de trabajo cerrado.</p>' +
        `<input type="checkbox" id="checkbox-terms">
        <label for="checkbox-terms">${checkboxText}</label>`,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
      },
      buttonsStyling: false,
      preConfirm: () => {
        const checkbox = document.getElementById('checkbox-terms') as HTMLInputElement;
        if (!checkbox?.checked) {
          Swal.showValidationMessage('Debes aceptar las condiciones para continuar');
          return false;
        }
      },
    })
  }

  const popupLMIAWithImmiland = () => {
    const checkboxText = 'Aceptar';
    Swal.fire({
      title: '<span style="color: #13C296;">Importante</span>',
      icon: 'info',
      html:
        '<p>Si su empleador aún no ha obtenido el LMIA, es esencial que nos comuniquemos con él, le pedimos proporcionarnos los datos de contacto en la parte final de la cotización.</p>' +
        '<p>Recuerde que no podrá solicitar su permiso de trabajo cerrado hasta que tengamos el LMIA aprobado.</p>' +
        `<input type="checkbox" id="checkbox-terms">
        <label for="checkbox-terms">${checkboxText}</label>`,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
      },
      buttonsStyling: false,
      preConfirm: () => {
        const checkbox = document.getElementById('checkbox-terms') as HTMLInputElement;
        if (!checkbox?.checked) {
          Swal.showValidationMessage('Debes aceptar las condiciones para continuar');
          return false;
        }
      },
    })
  }

  const popupCAQ = () => {
    Swal.fire({
      title: '<span style="color: #13C296;">Importante</span>',
      icon: 'info',
      html:
        "<p>El Certificat d'acceptation du Québec (CAQ) es requerido para la mayoría de los estudiantes y trabajadores extranjeros temporales que tienen la intención de residir en la provincia de Quebec.</p>",
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
      },
      buttonsStyling: false,
    })
  }

  const popupNoEtaWorkExtention = () => {
    const checkboxText = 'ACEPTAR';
    Swal.fire({
      title: '<span style="color: #13C296;">Importante</span>',
      icon: 'info',
      html: `
      <p style=" text-align: left">🔸Adicionalmente a su solicitud de extensión de permiso de estudio o trabajo es probable que necesite aplicar a una <b>visa de residente temporal para estudiantes o trabajadores dentro de Canadá </b>(documento de viaje) el cual debe estar vigente si planea viajar fuera de Canadá y re-ingresar. </p>
      <p style=" text-align: left">🔸Para verificar si requiere añadir este servicio verifique si la visa estampada en su pasaporte de visitante/estudiante/trabajador está vencida o próxima a vencerse o es válida solo para 1 entrada.</b>
      <p style=" text-align: left">🔸Tenga en cuenta que la solicitud de visa de residente temporal para estudiantes o trabajadores en Canadá debe hacerse por separado a la solicitud de extensión de estatus y en una segunda fase, es decir, una vez se apruebe su nuevo permiso de estudios o trabajo.</p>
		<input type="checkbox" id="checkbox-terms">
		<label for="checkbox-terms">${checkboxText}</label>`,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '¡Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
      },
      buttonsStyling: false,
      preConfirm: () => {
        const checkbox = document.getElementById('checkbox-terms') as HTMLInputElement;
        if (!checkbox?.checked) {
          Swal.showValidationMessage('Debes aceptar las condiciones para continuar');
          return false;
        }
      },
    });
  }

  const popupDisplayWokingOver = () => {
    const checkboxText = 'ACEPTAR';
    Swal.fire({
      title: '<span style="color: #13C296;">Importante</span>',
      icon: 'info',
      html: `
		<p>Puede ser elegible para un permiso de trabajo abierto por única vez  si su permiso de trabajo posterior a la graduación ha vencido o vencerá entre el 20 de septiembre de 2021 y el 31 de diciembre de 2023.</p>
		<input type="checkbox" id="checkbox-terms">
		<label for="checkbox-terms">${checkboxText}</label>
	  `,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: '¡Entendido!',
      customClass: {
        confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
      },
      buttonsStyling: false,
      preConfirm: () => {
        const checkbox = document.getElementById('checkbox-terms') as HTMLInputElement;
        if (!checkbox?.checked) {
          Swal.showValidationMessage('Debes aceptar las condiciones para continuar');
          return false;
        }
      },
    });
  }

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPurpose, setSelectedPurpose] = useState("");
  const [isnextbuttonDisabled, setIsnextbuttonDisabled] = useState(true);
  const handleOptionChange = (value: any, step: any) => {
    setSelectedPurpose(value);
    setCurrentStep(step);
    setIsnextbuttonDisabled(value === null);
  }

  const handleContinuarClick = () => {
    switch (selectedPurpose) {
      case "Visitor Visa Extension":
        handleChange('typeOfApplication', "Visitor Visa Extension");
        handleChange('purposeVisit', "Extensión y cambio de status");
        break;
      case "Study Visa Extension":
        handleChange('typeOfApplication', "Study Visa Extension");
        handleChange('purposeVisit', "Extensión y cambio de status");
        break;
      case "Residencia Permanente":
        handleChange('typeOfApplication', "Residencia Permanente");
        handleChange('purposeVisit', "Residencia Permanente");
        break;
      case "Work Visa Extension":
        handleChange('typeOfApplication', "Work Visa Extension");
        handleChange('purposeVisit', "Extensión y cambio de status");
        break;
      case "PGWP":
        handleChange('typeOfApplication', "PGWP");
        handleChange('purposeVisit', "PGWP");
        break;
      case "Co-op":
        handleChange('typeOfApplication', "Co-op work permit");
        handleChange('purposeVisit', "Co-op");
        break;
      case "Visa Renovation":
        handleChange('typeOfApplication', "Visa Renovation");
        handleChange('purposeVisit', "Visa Renovation");
        break;
      case "Permiso de trabajo Trabajador Vulnerable":
        handleChange('typeOfApplication', "Permiso de trabajo Trabajador Vulnerable");
        handleChange('purposeVisit', "Permiso de trabajo Trabajador Vulnerable");
        break;

      default:/* popupDisplayOthers(); */
        break;
    }

    setStep(currentStep);
  };

  return (
    <div className='my-4 h-full bg-white'>
      <Box>
        {/* start step 2 */}
        <form className='w-full' id='fromEl' style={{ fontFamily: 'Lato, sans-serif' }}>
          <div className='flex flex-wrap mb-6'>
            {step === 2 && <>
              <div style={{ padding: '30px' }}>
                <div className='titledivquestions'>
                  <h1>¿Cuál es el propósito de su solicitud?</h1>
                </div>
                <div className='container-radio-select flex flex-wrap gap-8'>
                  <div className='container-wrapper' style={{ display: 'flex', flexDirection: 'row', gap: '1rem', flexWrap: 'wrap' }}>
                    <div>
                      <label className="radio-selector">
                        <input
                          type="radio"
                          value="Visitor Visa Extension"
                          checked={selectedPurpose === "Visitor Visa Extension"}
                          onChange={() => {
                            handleOptionChange("Visitor Visa Extension", 3)
                          }}/>
                        <p>Visita</p>
                      </label>
                    </div>
                    <div>
                      <label className="radio-selector">
                        <input
                          type="radio"
                          value="Study Visa Extension"
                          checked={selectedPurpose === "Study Visa Extension"}
                          onChange={() => {
                            handleOptionChange("Study Visa Extension", 20)
                          }}/>
                        <p>Estudio</p>
                      </label>
                    </div>
                  </div>
                  <div className='container-wrapper' style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <div>
                      <label className="radio-selector">
                        <input
                          type="radio"
                          value="Work Visa Extension"
                          checked={selectedPurpose === "Work Visa Extension"}
                          onChange={() => handleOptionChange("Work Visa Extension", 18)}
                        />
                        <p>Trabajo</p>
                      </label>
                    </div>
                    <div>
                      <label className="radio-selector">
                        <input
                          type="radio"
                          value="Visa Renovation"
                          checked={selectedPurpose === "Visa Renovation"}
                          onChange={() => {
                            handleOptionChange("Visa Renovation", 3)
                          }}
                        />
                        <p>Visa residente temporal (TRV)</p>
                      </label>
                    </div>
                  </div>
                  <div className='container-wrapper' style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'flex-start' }}>
                    <div>
                      <label className="radio-selector">
                        <input
                          type="radio"
                          value="Residencia Permanente"
                          checked={selectedPurpose === "Residencia Permanente"}
                          onChange={() => handleOptionChange("Residencia Permanente", 3)}
                        />
                        <p>Residencia Permanente</p>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
                  <Button onClick={() => setStep(1)} className='backbutton-disc2'>
                    Atras
                  </Button>
                  <Button onClick={handleContinuarClick} className={`startbutton-disc2 ${isnextbuttonDisabled ? 'button-disabled' : ''}`} disabled={isnextbuttonDisabled}>
                    Continuar
                  </Button>
                </div>
              </div>
            </>}
          </div>
          {/* end page 2 */}

          {/* start page 3 */}
          {step === 3 ?
            <div>
              {currentMember.typeOfApplication !== 'Work Visa Extension' ?
                <div className='mx-16'>
                  <div className='flex flex-wrap justify-center'>
                    <BoxModal>
                      <Label>Seleccione el país emisor de su pasaporte</Label>
                      <div>
                        <CountrySelector
                          handleChange={(e: any) => {
                            { e.target.value != '' ? handleChange('passportCountry', e.target.value) : handleChange('passportCountry', undefined) }
                            validateEtaCountry(e.target.value)
                            { e.target.value != '' && currentMember.type === FamilyTypes.principal ? handleChange('countryOfCitizenship', e.target.value) : null }
                            /* { if (ETA.find((etas) => etas.country.toLocaleLowerCase() === e.target.value.toLocaleLowerCase()) === undefined && currentMember.typeOfApplication === 'Study Visa Extension') { popupNoEta() } } */
                          }}
                          value={currentMember.passportCountry || ''}
                          label='Seleccione el país emisor de su pasaporte'
                        />
                      </div>
                      {!currentMember.passportCountry && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
                        Debe seleccionar el pais emisor  de su pasaporte
                      </span>}
                    </BoxModal>
                    <BoxModal>
                      <Label>Provincia de destino:</Label>
                      <ProvinceSelector className='custom-input'
                        handleChange={(e: any) => {
                          { e.target.value != '' ? handleChange('province', e.target.value) : handleChange('province', undefined) }
                        }}
                        value={currentMember.province || ''}
                        label='Provinces'
                      />
                      {!currentMember.province && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe seleccionar una provincia
                      </span>}
                    </BoxModal>
                  </div>
                  <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
                    <Button onClick={() => setStep(2)} className='backbutton-disc2'>
                      Atras
                    </Button>
                    {/* {principalUsed()?.validEta === false ? */}
                    <Button
                      className='startbutton-disc2'
                      type="button"
                      onClick={() => {
                        { (!currentMember.province || !currentMember.passportCountry) ? setDisplayErrors(true) : setDisplayErrors(false) }
                        { currentMember.typeOfApplication === "Study Visa Extension" ? (currentMember.province === 'Quebec' && currentMember.wayChooseOnStudy == 'StudyPlusSix') || (currentMember.province === 'Quebec' && currentMember.typeOfStudyPermit === "Permiso de estudios programas académicos (más de 6 meses)") ? setStep(6) : setStep(5) : setStep(5) }
                        { currentMember.typeOfApplication === "Co-op work permit" ? setStep(16) : null }
                      }}
                    >Siguiente
                    </Button>
                    {/* :<Button
                      className='startbutton-disc2'
                      style={{ backgroundColor:'#DFE4EA'}}
                      type="button"
                      onClick={() => {
                        popupEtaNoApply()
                      }}
                    >Siguiente
                    </Button> */}
                  </div>
                  {/* end page 3 */}
                </div> :
                <div className='mx-16'>
                  <div className='flex flex-wrap justify-center'>
                    <BoxModal>
                      <Label>Seleccione el país emisor de su pasaporte</Label>
                      <div >
                        <CountrySelector
                          handleChange={(e: any) => {
                            if (e.target.value !== '') {
                              handleChange('countryOfCitizenship', e.target.value);
                            }
                            handleChange('passportCountry', e.target.value);
                            validateEtaCountry(e.target.value);
                            if (ETA.find((etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
                              popupNoEtaWorkExtention();
                            }
                          }}
                          value={currentMember.passportCountry || ''}
                          label='Seleccione el país emisor de su pasaporte'
                        />
                      </div>
                      {!currentMember.passportCountry && displayErrors === true && (
                        <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
                          Debe seleccionar el país emisor de su pasaporte
                        </span>
                      )}
                    </BoxModal>
                    {currentMember.passportCountry && ETA.find((etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) == undefined && (
                      <BoxModal>
                        <Label>Si se aprueba su solicitud IRCC no emitirá automáticamente una visa de residente temporal (TRV) ¿Desea incluir este servicio?</Label>
                        <Select className='custom-input'
                          name='Ha sido aceptado en una institución de aprendizaje designada'
                          defaultValue={currentMember.wantsTemporaryResidenceService}
                          style={{ backgroundColor: '#fff2f3 ' }}
                          labelId='demo-simple-select-label'
                          label='Seguro privado'
                          onChange={(e: any) => {
                            handleChange('wantsTemporaryResidenceService', e.target.value);
                          }}
                        >
                          <option value=''>Seleccione</option>
                          <option value='si'>Sí</option>
                          <option value='no'>No</option>
                        </Select>
                        {!currentMember.wantsTemporaryResidenceService && displayErrors === true && (
                          <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
                            Debe seleccionar si desea añadir el servicio de visa de residente temporal para estudiantes o trabajadores en Canadá (documento de viaje)
                          </span>
                        )}
                      </BoxModal>
                    )}
                  </div>
                  <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
                    <Button
                      onClick={() => setStep(2)}
                      className='backbutton-disc2'>
                      Atras
                    </Button>
                    <Button
                      type='button'
                      className='startbutton-disc2'
                      onClick={() => {
                        setStep(11)
                      }}>
                      Siguiente
                    </Button>
                  </div>
                  {/* end page 3 */}
                </div>}
            </div> : null}

          {/* start page 6 */}
          {step === 6 ? <>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div>
                <div className='titledivquestions' >
                  <h1>Para solicitar un permiso de trabajo cerrado con LMIA y/o permiso de estudio para dependientes en Quebec, <br />
                    es necesario obtener previamente un &#160;
                    <button type='button'
                      onClick={() => popupCAQ()}
                      style={{
                        color: 'blue',
                        textDecoration: 'underline'
                      }}>CAQ</button><br />
                    ¿Desea incluir este servicio?</h1>
                </div>
                <div className='containerbackandnextbuttons flex flex-wrap' style={{ fontFamily: 'Lato, sans-serif' }}>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(5)
                      handleChange('studyOption', "Deseo incluir CAQ (Certificado de aceptación de Quebec)")
                    }}>
                      Si
                    </button>
                  </div>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(5)
                      handleChange('studyOption', "NO deseo incluir CAQ (Certificado de aceptación de Quebec)")
                    }}>
                      No
                    </button>
                  </div>
                </div>
                <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
                  <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                    Atras
                  </Button>
                </div>
              </div>
            </div>
          </> : null}
          {/* end page 6 */}

          {/* end page 9 */}
          {step === 9 ? <>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div>
                <div className='titledivquestions' >
                  <h1>Seleccione la opción que corresponda a su situación: </h1>
                </div>
                <div className='containerbackandnextbuttons flex flex-wrap' style={{ fontFamily: 'Lato, sans-serif' }}>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(10)
                      handleChange('casePGWP', "caso 1")
                    }}>
                      Caso 1
                    </button>
                    <p className='bottoninfo'>(Usted es un recién graduado o está por graduarse de un programa de estudios a tiempo completo con una duración de al menos ocho meses en una institución de aprendizaje canadiense designada)</p>
                  </div>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      popupDisplayWokingOver()
                      setStep(10)
                      handleChange('casePGWP', "caso 2")
                    }}>
                      Caso 2
                    </button>
                    <p className='bottoninfo'>(Su permiso de trabajo de posgrado ha vencido o vencerá entre el 20 de septiembre de 2021 y el 31 de diciembre de 2023.)</p>
                  </div>
                </div>
                <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
                  <Button onClick={() => setStep(2)} className='backbutton-disc2'>
                    Atras
                  </Button>
                </div>
              </div>
            </div>
          </> : null}
          {/* end page 9 */}

          {/* start page 10 */}
          {step === 10 ? <div style={{ padding: '30px' }}>
            <div className='container-aplication' >
              <div className='flex flex-wrap justify-center'>
                <BoxModal>
                  <Label>Seleccione el país emisor de su pasaporte</Label>
                  <div >
                    <CountrySelector
                      handleChange={(e: any) => {
                        if (e.target.value !== '') {
                          handleChange('countryOfCitizenship', e.target.value);
                        }
                        handleChange('passportCountry', e.target.value);
                        validateEtaCountry(e.target.value);
                        if (ETA.find((etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
                          /* popupNoEta(); */
                        }
                      }}
                      value={currentMember.passportCountry || ''}
                      label='Seleccione el país emisor de su pasaporte'
                    />
                  </div>
                  {!currentMember.passportCountry && displayErrors === true && (
                    <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
                      Debe seleccionar el país emisor de su pasaporte
                    </span>
                  )}
                </BoxModal>
                <BoxModal>
                  <Label>Provincia de destino:</Label>
                  <ProvinceSelector className='custom-input'
                    handleChange={(e: any) => {
                      { e.target.value != '' ? handleChange('province', e.target.value) : handleChange('province', undefined) }
                    }}
                    value={currentMember.province || ''}
                    label='Provinces'
                  />
                  {!currentMember.province && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe seleccionar una provincia
                  </span>}
                </BoxModal>
              </div>
              <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
                <Button onClick={() => setStep(2)} className='backbutton-disc2'>
                  Atras</Button>
                <Button
                  type='button'
                  className='startbutton-disc2'
                  onClick={() => {
                    setStep(12)
                  }}>
                  Siguiente</Button>
              </div>
            </div>
            {/* end page 3 */}
          </div> : null}

          {/* end page 10 */}

          {/* start page 11 */}
          {step === 11 ? <>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div>
                <div className='titledivquestions' >
                  <h1>¿Cual es su estatus migratorio en Canada? </h1>
                </div>
                <div className='containerbackandnextbuttons flex flex-wrap' style={{ fontFamily: 'Lato, sans-serif' }}>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      popupVisitorExtention()
                      setStep(14)
                      handleChange('migratoryStatus', "Visitar")
                    }}>
                      Visitante
                    </button>
                  </div>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(14)
                      handleChange('migratoryStatus', "Trabajo")
                    }}>
                      Trabajador
                    </button>
                  </div>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(14)
                      handleChange('migratoryStatus', "Estudios")
                    }}>
                      Estudiante
                    </button>
                  </div>
                </div>
                <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
                  <Button onClick={() => setStep(10)} className='backbutton-disc2'>
                    Atras
                  </Button>
                </div>
              </div>
            </div>
          </> : null}
          {step === 13 ? <div>
            <div className='flex flex-wrap justify-center'>
              <BoxModal>
                <Label>Seleccione el país emisor de su pasaporte</Label>
                <div>
                  <CountrySelector
                    handleChange={(e: any) => {
                      { e.target.value != '' ? handleChange('passportCountry', e.target.value) : handleChange('passportCountry', undefined) }
                      validateEtaCountry(e.target.value)
                      { e.target.value != '' && currentMember.type === FamilyTypes.principal ? handleChange('countryOfCitizenship', e.target.value) : null }
                      /* { if (ETA.find((etas) => etas.country.toLocaleLowerCase() === e.target.value.toLocaleLowerCase()) === undefined && currentMember.typeOfApplication === 'Study Visa Extension') { popupNoEta() } } */
                    }}
                    value={currentMember.passportCountry || ''}
                    label='Seleccione el país emisor de su pasaporte'/>
                </div>
                {!currentMember.passportCountry && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
                  Debe seleccionar el pais emisor  de su pasaporte
                </span>}
              </BoxModal>
              <BoxModal>
                <Label>Provincia de destino:</Label>
                <ProvinceSelector className='custom-input'
                  handleChange={(e: any) => {
                    { e.target.value != '' ? handleChange('province', e.target.value) : handleChange('province', undefined) }
                  }}
                  value={currentMember.province || ''}
                  label='Provinces'
                />
                {!currentMember.province && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe seleccionar una provincia
                </span>}
              </BoxModal>
            </div>
            <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
              <Button
                onClick={() => setStep(2)}
                className='backbutton-disc2'>
                Atras
              </Button>
              <Button
                type="button"
                onClick={() => {
                  if (!currentMember.province || !currentMember.passportCountry) {
                    setDisplayErrors(true);
                  } else {
                    setDisplayErrors(false);
                    if (currentMember.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense') { setStep(15) }
                    if (currentMember.typeOfWorkPermit === 'Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá') { setStep(19) }
                    if (currentMember.typeOfWorkPermit === 'Permiso de trabajo Trabajador Vulnerable') { setStep(19) }
                  }
                }}
                className='startbutton-disc2'
              >Siguiente</Button>
            </div>
            {/* end page 13 */}
          </div> : null}

          {step === 14 ? <>
            <BoxModal >
              <Label>Selecione la provincia de destino:</Label>
              <ProvinceSelector className='custom-input'
                handleChange={(e: any) => {
                  { e.target.value != '' ? handleChange('province', e.target.value) : handleChange('province', undefined) }
                }}
                value={currentMember.province || ''}
                label='Provinces'
              />
              {!currentMember.province && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe seleccionar una provincia
              </span>}
            </BoxModal>
            <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
              <Button
                onClick={() => setStep(11)}
                className='backbutton-disc2'>
                Atras
              </Button>
              <Button
                type="button"
                onClick={() => {
                  if (!currentMember.province || !currentMember.passportCountry) {
                    setDisplayErrors(true);
                  } else {
                    setDisplayErrors(false);
                    setStep(15)
                  }
                }}
                className='startbutton-disc2'
              >Siguiente</Button>
            </div>
          </> : null}

          {step === 15 ? <>
            <div className='flex flex-wrap -mx-3 mb-6'>
              <div>
                <div className='titledivquestions' >
                  <h1>¿Usted califica como trabajador extranjero exento de &#160; <button type='button' onClick={() => popupDisplayLMIA()} style={{ color: 'blue', textDecoration: 'underline' }}>LMIA</button>? </h1>
                </div>
                <div className='containerbackandnextbuttons flex flex-wrap' style={{ fontFamily: 'Lato, sans-serif' }}>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(16)
                      handleChange('typeOfClosedWorkPermit', "REQUIERE de una evaluación de impacto del mercado laboral")
                    }}>
                      NO
                    </button>
                  </div>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(16)
                      handleChange('typeOfClosedWorkPermit', "ESTÁ EXENTA de una evaluación de impacto del mercado laboral")
                    }}>
                      SI
                    </button>
                  </div>
                </div>
                <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
                  <Button onClick={() => setStep(16)} className='backbutton-disc2'>
                    Atras
                  </Button>
                </div>
              </div>
            </div>
          </> : null}

          {step === 16 && currentMember.typeOfClosedWorkPermit === "ESTÁ EXENTA de una evaluación de impacto del mercado laboral" ?
            <>
              <div>
                {/* <div>
                  <div className='titledivquestions'
                    style={{
                      textAlign: 'justify',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '20px'
                    }}>
                    <a style={{ fontSize: '24px', fontWeight: '600', color: 'black' }}>Validación Oferta Laboral</a>
                    <h1>Para solicitar un permiso de trabajo cerrado exento de LMIA,
                      <br /> es necesario validar previamente la oferta laboral.
                      <br /> Para agilizar el proceso:
                      <b>¿Desea incluir los gastos de validación por su empleador?</b>
                    </h1>
                  </div>
                  <div className='containerbackandnextbuttons flex flex-wrap gap-8'>
                    <div>
                      <button
                        className={`rcorners2 ${selectedButton === "si" ? 'selected' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleButtonClick("si");
                        }}>
                        <p>SI</p>
                      </button>
                      <p className='bottoninfo'></p>
                    </div>
                    <div>
                      <button
                        className={`rcorners2 ${selectedButton === "no" ? 'selected' : ''}`}
                        onClick={(e) => {
                          popupNoPayForValidation();
                          e.preventDefault();
                          handleButtonClick("no");
                        }}>
                        <p>NO</p>
                      </button>
                      <p className='bottoninfo'></p>
                    </div>
                  </div>
                </div> */}
                <div style={{ height: '100%' }} className="rejected-doc-yellow mt-2 mb-2">
                  <div className="flex flex-row items-start gap-4 items-center" style={{ width: '100%' }}>
                    <div className="flex flex-col" style={{ width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                        <img src="/icons/warning.svg" alt="WarningIcon" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
                        <div style={{ gap: '5px', display: 'flex', flexDirection: 'column' }}>
                          <p style={{ color: '#9D5425', fontSize: '18px', margin: '0px', fontWeight: '700' }}>
                            <b>¿Desea recibir una plantilla para que su empleador conozca la estructura general de una
                              <br /> oferta de empleo?</b>
                          </p>
                          <p style={{ color: '#9D5425', fontSize: '18px', margin: '0px', fontWeight: '400' }}>
                            <a>Esta plantilla no debe considerarse como representación legal en  asuntos de derecho <br /> laboral. Su empleador es responsable de determinar  el alcance de este acuerdo y de <br /> redactar las cláusulas finales.</a> <br /> <b style={{ fontWeight: '600', fontSize: '17px' }}>Se añadirá un cargo de $150 CAD</b> <a style={{ fontWeight: '400' }}>a su cotización</a>
                          </p>
                        </div>
                      </div>
                      <div style={{ backgroundColor: '#F9FAFB', width: '100%', borderRadius: '10px', marginTop: '20px' }}>
                        <div style={{ fontSize: '1.1rem', margin: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px' }}>
                          <Checkbox onChange={(e) => {
                            setCheckboxChecked(e.target.checked);
                            currentMember.employletter = e.target.checked;
                          }} />
                          <b style={{
                            fontSize: '16px',
                            fontWeight: '400',
                            color: 'black' }}>
                              Si, deseo añadir este servicio adicional, tomando en cuenta que es necesario que mi
                              <br /> empleador consulte un abogado en derecho laboral de la provincia donde la empresa exista
                              <br /> para que el abogado revise la oferta y se asegure que la misma corresponde y respeta las
                              <br /> cláusulas laborales de la provincia.</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='button-container-disc2'
                  style={{
                    display: 'flex',
                    width: '100%',
                    gap: '3rem',
                    justifyContent: 'center',
                    marginTop: '2rem'
                  }}>
                  <Button
                    className='backbutton-disc2'
                    onClick={() => setStep(19)}>
                    Atras
                  </Button>
                  <Button
                    className={`startbutton-disc2 ${selectedButton && checkboxChecked ? '' : 'button-disabled'}`} 
                    onClick={() => setStep(19)}
                    /* disabled={!selectedButton} */>
                    Siguiente
                  </Button>
                </div>
              </div>
            </> : null}
          {step === 16 && currentMember.typeOfClosedWorkPermit === "REQUIERE de una evaluación de impacto del mercado laboral" ?
            <>
              <div>
                <div>
                  <div className='titledivquestions'>
                    <h1>Para solicitar un permiso de trabajo cerrado con LMIA, <br /> su empleador debe haber obtenido previamente una evaluación de impacto en el mercado laboral (LMIA). <br /> ¿Su empleador ya ha completado este paso?</h1>
                  </div>
                  <div className='containerbackandnextbuttons flex flex-wrap gap-8'>
                    <div>
                      <button className='rcorners2' onClick={(e) => {
                        e.preventDefault();
                        handleChange('wantAddLmiaPositive', "si");
                        currentMember.province === 'Quebec' ? setStep(17) : setStep(19)
                      }}>
                        <p>SI</p>
                      </button>
                    </div>
                    <div>
                      <button className='rcorners2'
                        onClick={(e) => {
                          popupLMIAWithImmiland();
                          e.preventDefault();
                          handleChange('wantAddLmiaPositive', "no");
                          currentMember.province === 'Quebec' ? setStep(17) : setStep(19)
                        }}>
                        <p>NO</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
                  <Button
                    type='button'
                    className='backbutton-disc2'
                    onClick={() => setStep(15)} >
                    Atras</Button>
                </div>
              </div>
            </> : null}

          {step === 17 && currentMember.province === "Quebec" ? <>
            <div>
              <div>
                <div className='titledivquestions'>
                  <h1>Para solicitar un permiso de trabajo cerrado con LMIA y/o permiso de estudio para dependientes en Quebec, <br /> es necesario obtener previamente un  &#160;
                    <button type='button'
                      onClick={() => popupCAQ()}
                      style={{
                        color: 'blue',
                        textDecoration: 'underline'
                      }}>CAQ</button><br />
                    ¿Desea incluir este servicio?</h1>
                </div>
                <div className='containerbackandnextbuttons flex flex-wrap gap-8'>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(5)
                      handleChange('wantCaq', "si")
                    }}>
                      <p>SI</p>
                    </button>
                  </div>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(5)
                      handleChange('wantCaq', "no")
                    }}>
                      <p>NO</p>
                    </button>
                  </div>
                </div>
              </div>
              <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
                <Button
                  className='backbutton-disc2'
                  onClick={() => setStep(16)}>
                  Atras
                </Button>
              </div>
            </div>
          </> : null}

          {step === 18 ? <>
            <div className='container-radio-select flex flex-wrap '>
              <div className='flex gap-4 responsive-div-questions'>
                <div>
                  <label className="radio-selector">
                    <input
                      type="radio"
                      value="PGWP"
                      checked={selectedPurpose === "PGWP"}
                      onChange={() => {
                        handleOptionChange("PGWP", 10)
                      }}
                    />
                    <p>PGWP</p>
                  </label>
                </div>
                <div>
                  <label className="radio-selector">
                    <input
                      type="radio"
                      value="Co-op"
                      checked={selectedPurpose === "Co-op"}
                      onChange={() => {
                        handleOptionChange("Co-op", 3)
                      }}
                    />
                    <p>Pasantías (Co-op)</p>
                  </label>
                </div>
              </div>
              <div className='flex gap-4 mt-4 responsive-div-questions'>
                <div>
                  <label className="radio-selector">
                    <input
                      type="radio"
                      value="Close Work Visa Extension"
                      checked={selectedPurpose === "Close Work Visa Extension"}
                      onChange={() => {
                        handleOptionChange("Close Work Visa Extension", 13)
                        handleChange('typeOfWorkPermit', "Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense")
                      }}
                    />
                    <p>Permiso de trabajo cerrado</p>
                  </label>
                </div>
                <div>
                  <label className="radio-selector">
                    <input
                      type="radio"
                      value="Open Work Visa Extension"
                      checked={selectedPurpose === "Open Work Visa Extension"}
                      onChange={() => {
                        handleOptionChange("Open Work Visa Extension", 13)
                        handleChange('typeOfWorkPermit', "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá")

                      }}
                    />
                    <p>Permiso de trabajo abierto para parejas</p>
                  </label>
                </div>


              </div>
              <div className='flex gap-4 mt-4 responsive-div-questions'>
                <div>

                  <label className="radio-selector">
                    <input
                      type="radio"
                      value="Vulnerable Worker"
                      checked={selectedPurpose === "Permiso de trabajo Trabajador Vulnerable"}
                      onChange={() => {
                        handleOptionChange("Permiso de trabajo Trabajador Vulnerable", 13)
                        handleChange('typeOfWorkPermit', "Permiso de trabajo Trabajador Vulnerable")
                      }}
                    />
                    <p>Permiso de trabajo Trabajador Vulnerable</p>
                  </label>
                </div>
              </div>
            </div>

            <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
              <Button
                className='backbutton-disc2'
                onClick={() => setStep(2)}>
                Atras
              </Button>
              <Button onClick={handleContinuarClick} className={`startbutton-disc2 ${isnextbuttonDisabled ? 'button-disabled' : ''}`} disabled={isnextbuttonDisabled}>
                Continuar
              </Button>
            </div>
          </> : null}

          {step === 20 ?
            <div>
              <div>
                <div className='titledivquestions'>
                  <h1>¿Qué clase de programa planea estudiar?</h1>
                </div>
                <div className='containerbackandnextbuttons flex flex-wrap gap-8'>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(3)
                      handleChange('typeOfStudyPermit', "Permiso de estudios programas académicos (más de 6 meses)")
                    }}>
                      <p>Acádemico	 </p>
                    </button>
                  </div>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(21)
                      handleChange('typeOfStudyPermit', "Permiso de estudios programas de idiomas (menos de 6 meses)")
                    }}>
                      <p>Idiomas</p>
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: 'center', marginBottom: '60px', marginTop: '60px', paddingBottom: '50px' }}>
                <Button
                  onClick={() => setStep(2)}
                  className='backbutton-disc2'>
                  Atras
                </Button>
              </div>
            </div> : null}

          {step === 21 ?
            <div>
              <div>
                <div className='titledivquestions'>
                  <h1>¿Su programa de idiomas tiene una duración de 22 semanas o más?</h1>
                </div>
                <div className='containerbackandnextbuttons flex flex-wrap gap-8'>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(3)
                      handleChange('wayChooseOnStudy', 'StudyPlusSix')
                    }}>
                      <p>Si</p>
                    </button>
                  </div>
                  <div>
                    <button className='rcorners2' onClick={() => {
                      setStep(3)
                      handleChange('wayChooseOnStudy', 'StudyLessSix')
                    }}>
                      <p>No</p>
                    </button>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: 'center', marginBottom: '60px', marginTop: '60px', paddingBottom: '50px' }}>
                <Button
                  onClick={() => setStep(2)}
                  className='backbutton-disc2'>
                  Atras
                </Button>
              </div>
            </div> : null}

          {step === 99 ?
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Label><h1>Opción de visa todavia en construccion, para mas informacio llene el siguiente formulario </h1>  </Label>
              <Label><a style={{ fontFamily: 'Lato, sans-serif', color: 'red', textDecoration: 'underline', fontSize: '1.5rem', marginBottom: '1rem' }} target="_blank" href='https://form.jotform.com/222796187234666' rel="noreferrer">https://form.jotform.com/222796187234666</a></Label>
              <img src="https://i.ibb.co/59HShXT/WORK-IN-PROCESS-title.png" alt="Imagen de obrero construyendo" />
            </div>
            : null}
        </form>
      </Box>
    </div>
  )
}
export default InsideCanada
