import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import Player from 'lottie-react'
import StartModule from './components/StartModule'
import { getFlowById } from '../modules/flows/core/_requests'
import InfoDisclaimer from './InfoDisclaimer/InfoDisclaimer'
import InfoDisclaimerTwo from './InfoDisclaimer/InfoDisclaimerTwo'
import InfoDisclaimerSix from './InfoDisclaimer/InfoDisclaimerSix'
import Shopping from './InfoDisclaimer/Shopping'
import { getAllProduct } from 'modules/flows/products/request'
import colores2 from './gradient-background-law.png'
import logolawAnimation from '../FormBuilder/logolaw.json'
import './FormBuilder.css'

// Declaración para Weglot
declare const Weglot: any;

const FormBuilder = () => {
  const { id } = useParams()
  const { data } = useQuery(['listFlows'], () => getFlowById(id ? id : ''))
  const [showGif, setShowGif] = useState(true)
  const [InfoDisclaimerStep, setInfoDisclaimerStep] = useState(1)
  const [showLanguageSelector, setShowLanguageSelector] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)
  const { data: products, isLoading: isLoadingProducts, isSuccess } = useQuery(['getAllProduct'], () => getAllProduct('limit=1000&page=1&name='))

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGif(false)
      setShowLanguageSelector(true) // Mostrar selector de idioma después del GIF
    }, 6000)
    return () => clearTimeout(timer)
  }, [])

  if (isLoadingProducts && !showGif) {
    return (<>Cargando Productos...</>)
  }

  if (isSuccess) {
    localStorage.setItem('products', JSON.stringify(products))
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGif(false)
      setShowLanguageSelector(true) // Mostrar selector de idioma después del GIF
    }, 6000)
    return () => clearTimeout(timer)
  }, [])

  // Manejar el cambio de paso y actualizar la historia sin cambiar la URL
  const changeStep: React.Dispatch<React.SetStateAction<number>> = (newStep) => {
    setInfoDisclaimerStep(newStep);
    // Optionally push state to history
    window.history.pushState({ step: newStep }, '', window.location.href);
  };
  

  // Escuchar cambios en la historia del navegador
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      const state = event.state as { step?: number }
      if (state && state.step !== undefined) {
        setInfoDisclaimerStep(state.step)
      }
    }

    // Registrar el evento de escucha para los cambios en la historia
    window.addEventListener('popstate', handlePopState)

    // Limpiar el evento de escucha cuando el componente se desmonte
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  const handleLanguageSelect = (language: string) => {
    setSelectedLanguage(language)
    setShowLanguageSelector(false)
    if (language === 'es') {
      Weglot.switchTo('es')
    } else if (language === 'en') {
      Weglot.switchTo('en')
    } else if (language === 'fr') {
      Weglot.switchTo('fr')
    }
    changeStep(1) // Iniciar en el paso 1
  }

  return (
    <>
      {showGif ? (
        <div className='center-animation'>
          <Player
            animationData={logolawAnimation}
            className='lottie-container'
            loop={false} // Set loop to false
            autoplay
          />
        </div>
      ) : showLanguageSelector ? (
        <div className='language-selector-container'>
          <h2 className='language-title'>
            idioma / language / langue
          </h2>

          <div className='language-buttons'>
            <button onClick={() => handleLanguageSelect('es')} className='language-button'>
              <img src='https://flagcdn.com/es.svg' alt='Español' className='flag-icon' />
              <span className="flag-label">Español</span>
            </button>
            <button onClick={() => handleLanguageSelect('en')} className='language-button'>
              <img src='https://flagcdn.com/us.svg' alt='English' className='flag-icon' />
              <span className="flag-label">English</span>
            </button>
            <button onClick={() => handleLanguageSelect('fr')} className='language-button'>
              <img src='https://flagcdn.com/fr.svg' alt='Français' className='flag-icon' />
              <span className="flag-label">Français</span>
            </button>
          </div>
        </div>
      ) : (
        <>
          <div style={{ width: '100%', height: '30px', marginBottom: '10px', backgroundImage: `url(${colores2})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h1 style={{ color: 'white', fontSize: '1rem' }}>
              Immiland lo tiene todo
            </h1>
          </div>
          <div
            className='form-builder'
            style={{
              margin: '0 auto',
              boxShadow: 'rgb(255 255 255 / 16%) 0px 10px 36px 0px, rgb(255 255 255 / 6%) 0px 0px 0px 1px',
              minHeight: '31rem'
            }}
          >
            <div>
              <h1 style={{ fontSize: '30px', textAlign: 'center' }}>
                {data?.id === 'ae272be0-dad9-4253-9f52-7efd444e203d' && (
                  <div style={{ fontFamily: 'Lato, sans-serif' }}>Educación Immiland</div>
                )}
              </h1>
            </div>
            <div>
              {InfoDisclaimerStep === 1 && <InfoDisclaimer setInfoDisclaimerStep={changeStep} />}
              {InfoDisclaimerStep === 2 && <InfoDisclaimerTwo setInfoDisclaimerStep={changeStep} />}
              {InfoDisclaimerStep === 5 && data && <StartModule codeData={data.data} nodes={data.nodes}/>}
              {InfoDisclaimerStep === 10 && data && <Shopping setInfoDisclaimerStep={changeStep} />}
              {InfoDisclaimerStep === 6 && data && <InfoDisclaimerSix setInfoDisclaimerStep={changeStep} />}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default FormBuilder
