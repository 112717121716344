import React, { useContext, useEffect, useState } from 'react'

import { RootStoreContext } from 'stores/rootStore'
import { observer } from 'mobx-react'


import { Product } from 'modules/flows/products/module'


interface IProps {
	products: Product[]
	setMatterFromResumeComponent: React.Dispatch<React.SetStateAction<string>>
	setApplicantInfoMatterFromResumeComponent: React.Dispatch<React.SetStateAction<string>>
}



const Resumen: React.FC<IProps> = ({ setApplicantInfoMatterFromResumeComponent, setMatterFromResumeComponent }) => {
	const rootStore = useContext(RootStoreContext)
	const [triggerUseEffect, setTriggerUseEffect] = useState(0)
	const [applicantProductInformation, setApplicantProductInformation] = useState<any[]>([])
	const {
		familyMembers,
		setResumeService,
		getBiometrics,
		getTransitVisaDetailsFee,
		getDependantVisitorLessFive,
		getEtaDetails,
		getSubmission,
		getSuperVisaDetails,
		getSuperVisaFederalFee,
		getTransitVisaDetails,
		getVisitorFederal,
		getVisitorVisaDetails,
		getVisitorVisaDetailsLessThanSixMonths,
		getEtaFederalFee,
		getResumeService,
		getCAQStudentGovernmentFee,
		getCalculateCAQStudent,
		getCalculateCAQWorker,
		getCalculateCAQWorkerGovermentFees,
		getCalculateCAQMinor,
		getCalculateCAQMinorGovermentFees,
		getsetCalculatestudyPermitAcademicDetails,
		getCalculatestudyPermitFederalFeeDetails,
		getCalculatestudyPermitAcademicStreamDetails,
		getCalculatestudyPermitLangDetails,
		getPartnerOpenWorkSpouse,
		getDependantVisitorPlusFive,
		getOpenWorkPermitHolderFederalFee,
		getWorkPermitIncludesExtentionFederalFee,
		getPartnerWorkingHolidayON,
		getVisitorRecord,
		getVisitorRecordFederal,
		getStudyPermitExtentionON,
		getSpousePartnerOpenWorkPermitExtentionON,
		getTravelDocumentRenovationStickerVisaON,
		getDependantStudyPermitExtentionOverFiveDetails,
		getdependantStudyPermitExtentionLessFiveDetails,
		getSponsorshipApplicationIn,
		getPartnerOpenWorkPermitPOE,
		getPGWPEON,
		getPGWPPOE,
		getPGWPEx,
		getWorkPermitExemptONDetails,
		getWorkPermitExemptPOE,
		getWorkPermitPositiveON,
		getWorkPermitPositivePOE,
		getPermanentResidenceSponsorshipFederalFee,
		getOpenWorkPermitMainApplicant,
		getCSQ,
		getCoop,
		getCSQGovernmentApplicationFee,
		getCSQGovernmentApplicationFeeDependentChild,
		getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded,
		getSponsorshipApplicationOut,
		getExpressEntryExpressionOfInterestPhase1,
		getSpousePartnerExpressEntryExpressionOfInterestPhase1,
		getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee,
		getLMIAExExtentionPOE,
		getLMIAPosExtentionPOE,
		getLMIAExExtentionON,
		setAnswers,
		getAtip,
		getAtipFee,
		resumeService,
		getExpiredPermit,
		getExpiredPermitMoreThan90,
		getExpiredRestoration,
		setResumeServiceToEmptyString,
		setProductAplicantService,
		getBiometricsIndependantChildren,
		getWorkingHolidayFee,
		getInternationalExperienceCanadaWorkPermit,
		getQCsponsorship,
		getQCsponsorshipFee,
		getResidenceSponsorshipFeeParents,
		getQCAddMember,
		getdependantChildIndependentSponsor,
		getSpouseExEntry,
		getdependantExEntry,
		getExpressEntryPhaseTwo,
		getPReconomicPA,
		getPReconomicSpouse,
		getPReconomicDepChild,
		getRSWPphaseone,
		getspouseRSWPphaseone,
		getCSQDependantChild,
		getCSQpr,
		getdepedantotherPRFederal,
		getotherPRFederal,
		getspouseotherPRFederal,
		getCSQSpouseParnerFee,
		getCSQSpouseParner,
		getDependentPN,
		getPN,
		getPNPProgram,
		getSpousePN,
		getCSQApplicationFeeMainApplicant,
		getStudyLanguagePermitExtentionON,
		getWorkPermitExemptONDetailsInsideCanada,
		getEmploymentletter,
		getOpenWorkPermitVulnerable,
	} = rootStore.familyStore

	const addingAplicantProductInformation = (newObject: any) => {

		if (newObject) {
			newObject.forEach((newApplicant: any) => {
				const exists = applicantProductInformation.some(
					(existingApplicant) =>
						existingApplicant.memberName === newApplicant.memberName &&
						existingApplicant.memberType === newApplicant.memberType &&
						existingApplicant.productName === newApplicant.productName &&
						existingApplicant.productId === newApplicant.productId
				);

				if (!exists) {
					applicantProductInformation.push(newApplicant);
				}
			});
		}
	}
	if (/* active1 === true &&  */triggerUseEffect === 0) {
		setResumeServiceToEmptyString()
		setApplicantProductInformation([])
		if (resumeService === "") {
			if (getBiometricsIndependantChildren().quantity > 0 &&
				!getResumeService().split(',').map(service => service.trim()).includes(getBiometricsIndependantChildren().name)) {
				setResumeService(getBiometricsIndependantChildren().quantity + " " + getBiometricsIndependantChildren().name + " " + getBiometricsIndependantChildren().description);
				if (getBiometricsIndependantChildren().memberInfo) {
					const modifiedMemberInfo = getBiometricsIndependantChildren().memberInfo?.map((member) => ({
						...member,
						"productName": getBiometricsIndependantChildren().name,
						"productId": getBiometricsIndependantChildren().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo);
				}
			}

			if (getBiometrics().quantity > 0 &&
				!getResumeService().split(',').map(service => service.trim()).includes(getBiometrics().name)) {
				setResumeService(getBiometrics().quantity + " " + getBiometrics().name + " " + getBiometrics().description);
				if (getBiometrics().memberInfo) {
					const modifiedMemberInfo = getBiometrics().memberInfo?.map((member) => ({
						...member,
						"productName": getBiometrics().name,
						"productId": getBiometrics().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getDependantVisitorLessFive().quantity > 0 &&
				!getResumeService().split(',').map(service => service.trim()).includes(getDependantVisitorLessFive().name)) {
				setResumeService(
					getDependantVisitorLessFive().quantity + " " + getDependantVisitorLessFive().name + " " + getDependantVisitorLessFive().description);
				if (getDependantVisitorLessFive().memberInfo) {
					const modifiedMemberInfo = getDependantVisitorLessFive().memberInfo?.map((member) => ({
						...member,
						"productName": getDependantVisitorLessFive().name,
						"productId": getDependantVisitorLessFive().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}


			if (getExpiredPermit().quantity > 0 && !getResumeService().includes(getExpiredPermit().name)) {
				setResumeService(getExpiredPermit().quantity + " " + getExpiredPermit().name + " " + getExpiredPermit().description);
				if (getExpiredPermit().memberInfo) {
					const modifiedMemberInfo = getExpiredPermit().memberInfo?.map((member) => ({
						...member,
						"productName": getExpiredPermit().name,
						"productId": getExpiredPermit().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getAtip().quantity > 0 && !getResumeService().includes(getAtip().name)) {
				setResumeService(getAtip().quantity + " " + getAtip().name + " " + getAtip().description);
				if (getAtip().memberInfo) {
					const modifiedMemberInfo = getAtip().memberInfo?.map((member) => ({
						...member,
						"productName": getAtip().name,
						"productId": getAtip().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getAtipFee().quantity > 0 && !getResumeService().includes(getAtipFee().name)) {
				setResumeService(getAtipFee().quantity + " " + getAtipFee().name + " " + getAtipFee().description);
				if (getAtipFee().memberInfo) {
					const modifiedMemberInfo = getAtipFee().memberInfo?.map((member) => ({
						...member,
						"productName": getAtipFee().name,
						"productId": getAtipFee().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getExpiredPermitMoreThan90().quantity > 0 && !getResumeService().includes(getExpiredPermitMoreThan90().name)) {
				setResumeService(getExpiredPermitMoreThan90().quantity + " " + getExpiredPermitMoreThan90().name + " " + getExpiredPermitMoreThan90().description);
				if (getExpiredPermitMoreThan90().memberInfo) {
					const modifiedMemberInfo = getExpiredPermitMoreThan90().memberInfo?.map((member) => ({
						...member,
						"productName": getExpiredPermitMoreThan90().name,
						"productId": getExpiredPermitMoreThan90().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}



			if (getExpiredRestoration().quantity > 0 && !getResumeService().includes(getExpiredRestoration().name)) {
				setResumeService(getExpiredRestoration().quantity + " " + getExpiredRestoration().name + " " + getExpiredRestoration().description);
				if (getExpiredRestoration().memberInfo) {
					const modifiedMemberInfo = getExpiredRestoration().memberInfo?.map((member) => ({
						...member,
						"productName": getExpiredRestoration().name,
						"productId": getExpiredRestoration().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getEtaDetails().quantity > 0 && !getResumeService().includes(getEtaDetails().name)) {
				setResumeService(getEtaDetails().quantity + " " + getEtaDetails().name + " " + getEtaDetails().description);
				if (getEtaDetails().memberInfo) {
					const modifiedMemberInfo = getEtaDetails().memberInfo?.map((member) => ({
						...member,
						"productName": getEtaDetails().name,
						"productId": getEtaDetails().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getSubmission().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getSubmission().name)) {
				setResumeService(getSubmission().quantity + " " + getSubmission().name + " " + getSubmission().description);
				if (getSubmission().memberInfo) {
					const modifiedMemberInfo = getSubmission().memberInfo?.map((member) => ({
						...member,
						"productName": getSubmission().name,
						"productId": getSubmission().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}


			if (getSuperVisaDetails().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getSuperVisaDetails().name)) {
				setResumeService(getSuperVisaDetails().quantity + " " + getSuperVisaDetails().name + " " + getSuperVisaDetails().description);
				if (getSuperVisaDetails().memberInfo) {
					const modifiedMemberInfo = getSuperVisaDetails().memberInfo?.map((member) => ({
						...member,
						"productName": getSuperVisaDetails().name,
						"productId": getSuperVisaDetails().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getStudyLanguagePermitExtentionON().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getStudyLanguagePermitExtentionON().name)) {
				setResumeService(getStudyLanguagePermitExtentionON().quantity + " " + getStudyLanguagePermitExtentionON().name + " " + getStudyLanguagePermitExtentionON().description);
				if (getStudyLanguagePermitExtentionON().memberInfo) {
					const modifiedMemberInfo = getStudyLanguagePermitExtentionON().memberInfo?.map((member) => ({
						...member,
						"productName": getStudyLanguagePermitExtentionON().name,
						"productId": getStudyLanguagePermitExtentionON().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getWorkPermitExemptONDetailsInsideCanada().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getWorkPermitExemptONDetailsInsideCanada().name)) {
				setResumeService(getWorkPermitExemptONDetailsInsideCanada().quantity + " " + getWorkPermitExemptONDetailsInsideCanada().name + " " + getWorkPermitExemptONDetailsInsideCanada().description);
				if (getWorkPermitExemptONDetailsInsideCanada().memberInfo) {
					const modifiedMemberInfo = getWorkPermitExemptONDetailsInsideCanada().memberInfo?.map((member) => ({
						...member,
						"productName": getWorkPermitExemptONDetailsInsideCanada().name,
						"productId": getWorkPermitExemptONDetailsInsideCanada().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}


			if (getSuperVisaFederalFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getSuperVisaFederalFee().name)) {
				setResumeService(getSuperVisaFederalFee().quantity + " " + getSuperVisaFederalFee().name + " " + getSuperVisaFederalFee().description);
				if (getSuperVisaFederalFee().memberInfo) {
					const modifiedMemberInfo = getSuperVisaFederalFee().memberInfo?.map((member) => ({
						...member,
						"productName": getSuperVisaFederalFee().name,
						"productId": getSuperVisaFederalFee().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getTransitVisaDetails().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getTransitVisaDetails().name)) {
				setResumeService(getTransitVisaDetails().quantity + " " + getTransitVisaDetails().name + " " + getTransitVisaDetails().description);
				if (getTransitVisaDetails().memberInfo) {
					const modifiedMemberInfo = getTransitVisaDetails().memberInfo?.map((member) => ({
						...member,
						"productName": getTransitVisaDetails().name,
						"productId": getTransitVisaDetails().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}


			if (getVisitorVisaDetailsLessThanSixMonths().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getVisitorVisaDetailsLessThanSixMonths().name)) {
				setResumeService(getVisitorVisaDetailsLessThanSixMonths().quantity + " " + getVisitorVisaDetailsLessThanSixMonths().name + " " + getVisitorVisaDetailsLessThanSixMonths().description);
				if (getVisitorVisaDetailsLessThanSixMonths().memberInfo) {
					const modifiedMemberInfo = getVisitorVisaDetailsLessThanSixMonths().memberInfo?.map((member) => ({
						...member,
						"productName": getVisitorVisaDetailsLessThanSixMonths().name,
						"productId": getVisitorVisaDetailsLessThanSixMonths().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getTransitVisaDetailsFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getTransitVisaDetailsFee().name)) {
				setResumeService(getTransitVisaDetailsFee().quantity + " " + getTransitVisaDetailsFee().name + " " + getTransitVisaDetailsFee().description);
				if (getTransitVisaDetailsFee().memberInfo) {
					const modifiedMemberInfo = getTransitVisaDetailsFee().memberInfo?.map((member) => ({
						...member,
						"productName": getTransitVisaDetailsFee().name,
						"productId": getTransitVisaDetailsFee().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getVisitorFederal().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getVisitorFederal().name)) {
				setResumeService(getVisitorFederal().quantity + " " + getVisitorFederal().name + " " + getVisitorFederal().description);
				if (getVisitorFederal().memberInfo) {
					const modifiedMemberInfo = getVisitorFederal().memberInfo?.map((member) => ({
						...member,
						"productName": getVisitorFederal().name,
						"productId": getVisitorFederal().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getVisitorVisaDetails().quantity > 0 &&
				!getResumeService().split(',').map(service => service.trim()).includes(getVisitorVisaDetails().name)) {
				console.log('Vista del resume')
				setResumeService(getVisitorVisaDetails().quantity + " " + getVisitorVisaDetails().name + " " + getVisitorVisaDetails().description);
				if (getVisitorVisaDetails().memberInfo) {
					const modifiedMemberInfo = getVisitorVisaDetails().memberInfo?.map((member) => ({
						...member,
						"productName": getVisitorVisaDetails().name,
						"productId": getVisitorVisaDetails().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getEtaFederalFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getEtaFederalFee().name)) {
				setResumeService(getEtaFederalFee().quantity + " " + getEtaFederalFee().name + " " + getEtaFederalFee().description);
				if (getEtaFederalFee().memberInfo) {
					const modifiedMemberInfo = getEtaFederalFee().memberInfo?.map((member) => ({
						...member,
						"productName": getEtaFederalFee().name,
						"productId": getEtaFederalFee().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getCAQStudentGovernmentFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCAQStudentGovernmentFee().name)) {
				setResumeService(getCAQStudentGovernmentFee().quantity + " " + getCAQStudentGovernmentFee().name + " " + getCAQStudentGovernmentFee().description);
				if (getCAQStudentGovernmentFee().memberInfo) {
					const modifiedMemberInfo = getCAQStudentGovernmentFee().memberInfo?.map((member) => ({
						...member,
						"productName": getCAQStudentGovernmentFee().name,
						"productId": getCAQStudentGovernmentFee().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getCalculateCAQStudent().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCalculateCAQStudent().name)) {
				setResumeService(getCalculateCAQStudent().quantity + " " + getCalculateCAQStudent().name + " " + getCalculateCAQStudent().description);
				if (getCalculateCAQStudent().memberInfo) {
					const modifiedMemberInfo = getCalculateCAQStudent().memberInfo?.map((member) => ({
						...member,
						"productName": getCalculateCAQStudent().name,
						"productId": getCalculateCAQStudent().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getCalculateCAQWorker().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCalculateCAQWorker().name)) {
				setResumeService(getCalculateCAQWorker().quantity + " " + getCalculateCAQWorker().name + " " + getCalculateCAQWorker().description);
				if (getCalculateCAQWorker().memberInfo) {
					const modifiedMemberInfo = getCalculateCAQWorker().memberInfo?.map((member) => ({
						...member,
						"productName": getCalculateCAQWorker().name,
						"productId": getCalculateCAQWorker().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getCalculateCAQWorkerGovermentFees().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCalculateCAQWorkerGovermentFees().name)) {
				setResumeService(getCalculateCAQWorkerGovermentFees().quantity + " " + getCalculateCAQWorkerGovermentFees().name + " " + getCalculateCAQWorkerGovermentFees().description);
				if (getCalculateCAQWorkerGovermentFees().memberInfo) {
					const modifiedMemberInfo = getCalculateCAQWorkerGovermentFees().memberInfo?.map((member) => ({
						...member,
						"productName": getCalculateCAQWorkerGovermentFees().name,
						"productId": getCalculateCAQWorkerGovermentFees().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getCalculateCAQMinor().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCalculateCAQMinor().name)) {
				setResumeService(getCalculateCAQMinor().quantity + " " + getCalculateCAQMinor().name + " " + getCalculateCAQMinor().description);

				if (getCalculateCAQMinor().memberInfo) {
					const modifiedMemberInfo = getCalculateCAQMinor().memberInfo?.map((member) => ({
						...member,
						"productName": getCalculateCAQMinor().name,
						"productId": getCalculateCAQMinor().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getCalculateCAQMinorGovermentFees().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCalculateCAQMinorGovermentFees().name)) {
				setResumeService(getCalculateCAQMinorGovermentFees().quantity + " " + getCalculateCAQMinorGovermentFees().name + " " + getCalculateCAQMinorGovermentFees().description);

				if (getCalculateCAQMinorGovermentFees().memberInfo) {
					const modifiedMemberInfo = getCalculateCAQMinorGovermentFees().memberInfo?.map((member) => ({
						...member,
						"productName": getCalculateCAQMinorGovermentFees().name,
						"productId": getCalculateCAQMinorGovermentFees().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getsetCalculatestudyPermitAcademicDetails().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getsetCalculatestudyPermitAcademicDetails().name)) {
				setResumeService(getsetCalculatestudyPermitAcademicDetails().quantity + " " + getsetCalculatestudyPermitAcademicDetails().name + " " + getsetCalculatestudyPermitAcademicDetails().description);

				if (getsetCalculatestudyPermitAcademicDetails().memberInfo) {
					const modifiedMemberInfo = getsetCalculatestudyPermitAcademicDetails().memberInfo?.map((member) => ({
						...member,
						"productName": getsetCalculatestudyPermitAcademicDetails().name,
						"productId": getsetCalculatestudyPermitAcademicDetails().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getCalculatestudyPermitFederalFeeDetails().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCalculatestudyPermitFederalFeeDetails().name)) {
				setResumeService(getCalculatestudyPermitFederalFeeDetails().quantity + " " + getCalculatestudyPermitFederalFeeDetails().name + " " + getCalculatestudyPermitFederalFeeDetails().description);

				if (getCalculatestudyPermitFederalFeeDetails().memberInfo) {
					const modifiedMemberInfo = getCalculatestudyPermitFederalFeeDetails().memberInfo?.map((member) => ({
						...member,
						"productName": getCalculatestudyPermitFederalFeeDetails().name,
						"productId": getCalculatestudyPermitFederalFeeDetails().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getCalculatestudyPermitAcademicStreamDetails().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCalculatestudyPermitAcademicStreamDetails().name)) {
				setResumeService(getCalculatestudyPermitAcademicStreamDetails().quantity + " " + getCalculatestudyPermitAcademicStreamDetails().name + " " + getCalculatestudyPermitAcademicStreamDetails().description);

				if (getCalculatestudyPermitAcademicStreamDetails().memberInfo) {
					const modifiedMemberInfo = getCalculatestudyPermitAcademicStreamDetails().memberInfo?.map((member) => ({
						...member,
						"productName": getCalculatestudyPermitAcademicStreamDetails().name,
						"productId": getCalculatestudyPermitAcademicStreamDetails().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getCalculatestudyPermitLangDetails().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCalculatestudyPermitLangDetails().name)) {
				setResumeService(getCalculatestudyPermitLangDetails().quantity + " " + getCalculatestudyPermitLangDetails().name + " " + getCalculatestudyPermitLangDetails().description);

				if (getCalculatestudyPermitLangDetails().memberInfo) {
					const modifiedMemberInfo = getCalculatestudyPermitLangDetails().memberInfo?.map((member) => ({
						...member,
						"productName": getCalculatestudyPermitLangDetails().name,
						"productId": getCalculatestudyPermitLangDetails().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getPartnerOpenWorkSpouse().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPartnerOpenWorkSpouse().name)) {
				setResumeService(getPartnerOpenWorkSpouse().quantity + " " + getPartnerOpenWorkSpouse().name + " " + getPartnerOpenWorkSpouse().description);

				if (getPartnerOpenWorkSpouse().memberInfo) {
					const modifiedMemberInfo = getPartnerOpenWorkSpouse().memberInfo?.map((member) => ({
						...member,
						"productName": getPartnerOpenWorkSpouse().name,
						"productId": getPartnerOpenWorkSpouse().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getDependantVisitorPlusFive().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getDependantVisitorPlusFive().name)) {
				setResumeService(getDependantVisitorPlusFive().quantity + " " + getDependantVisitorPlusFive().name + " " + getDependantVisitorPlusFive().description);

				if (getDependantVisitorPlusFive().memberInfo) {
					const modifiedMemberInfo = getDependantVisitorPlusFive().memberInfo?.map((member) => ({
						...member,
						"productName": getDependantVisitorPlusFive().name,
						"productId": getDependantVisitorPlusFive().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getOpenWorkPermitHolderFederalFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getOpenWorkPermitHolderFederalFee().name)) {
				setResumeService(getOpenWorkPermitHolderFederalFee().quantity + " " + getOpenWorkPermitHolderFederalFee().name + " " + getOpenWorkPermitHolderFederalFee().description);

				if (getOpenWorkPermitHolderFederalFee().memberInfo) {
					const modifiedMemberInfo = getOpenWorkPermitHolderFederalFee().memberInfo?.map((member) => ({
						...member,
						"productName": getOpenWorkPermitHolderFederalFee().name,
						"productId": getOpenWorkPermitHolderFederalFee().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getWorkPermitIncludesExtentionFederalFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getWorkPermitIncludesExtentionFederalFee().name)) {
				setResumeService(getWorkPermitIncludesExtentionFederalFee().quantity + " " + getWorkPermitIncludesExtentionFederalFee().name + " " + getWorkPermitIncludesExtentionFederalFee().description);

				if (getWorkPermitIncludesExtentionFederalFee().memberInfo) {
					const modifiedMemberInfo = getWorkPermitIncludesExtentionFederalFee().memberInfo?.map((member) => ({
						...member,
						"productName": getWorkPermitIncludesExtentionFederalFee().name,
						"productId": getWorkPermitIncludesExtentionFederalFee().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getPartnerWorkingHolidayON().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPartnerWorkingHolidayON().name)) {
				setResumeService(getPartnerWorkingHolidayON().quantity + " " + getPartnerWorkingHolidayON().name + " " + getPartnerWorkingHolidayON().description);

				if (getPartnerWorkingHolidayON().memberInfo) {
					const modifiedMemberInfo = getPartnerWorkingHolidayON().memberInfo?.map((member) => ({
						...member,
						"productName": getPartnerWorkingHolidayON().name,
						"productId": getPartnerWorkingHolidayON().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getVisitorRecord().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getVisitorRecord().name)) {
				setResumeService(getVisitorRecord().quantity + " " + getVisitorRecord().name + " " + getVisitorRecord().description);

				if (getVisitorRecord().memberInfo) {
					const modifiedMemberInfo = getVisitorRecord().memberInfo?.map((member) => ({
						...member,
						"productName": getVisitorRecord().name,
						"productId": getVisitorRecord().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getVisitorRecordFederal().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getVisitorRecordFederal().name)) {
				setResumeService(getVisitorRecordFederal().quantity + " " + getVisitorRecordFederal().name + " " + getVisitorRecordFederal().description);

				if (getVisitorRecordFederal().memberInfo) {
					const modifiedMemberInfo = getVisitorRecordFederal().memberInfo?.map((member) => ({
						...member,
						"productName": getVisitorRecordFederal().name,
						"productId": getVisitorRecordFederal().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getSponsorshipApplicationIn().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getSponsorshipApplicationIn().name)) {
				setResumeService(getSponsorshipApplicationIn().quantity + " " + getSponsorshipApplicationIn().name + " " + getSponsorshipApplicationIn().description);

				if (getSponsorshipApplicationIn().memberInfo) {
					const modifiedMemberInfo = getSponsorshipApplicationIn().memberInfo?.map((member) => ({
						...member,
						"productName": getSponsorshipApplicationIn().name,
						"productId": getSponsorshipApplicationIn().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}


			if (getPermanentResidenceSponsorshipFederalFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPermanentResidenceSponsorshipFederalFee().name)) {
				setResumeService(getPermanentResidenceSponsorshipFederalFee().quantity + " " + getPermanentResidenceSponsorshipFederalFee().name + " " + getPermanentResidenceSponsorshipFederalFee().description);

				if (getPermanentResidenceSponsorshipFederalFee().memberInfo) {
					const modifiedMemberInfo = getPermanentResidenceSponsorshipFederalFee().memberInfo?.map((member) => ({
						...member,
						"productName": getPermanentResidenceSponsorshipFederalFee().name,
						"productId": getPermanentResidenceSponsorshipFederalFee().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getOpenWorkPermitMainApplicant().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getOpenWorkPermitMainApplicant().name)) {
				setResumeService(getOpenWorkPermitMainApplicant().quantity + " " + getOpenWorkPermitMainApplicant().name + " " + getOpenWorkPermitMainApplicant().description);

				if (getOpenWorkPermitMainApplicant().memberInfo) {
					const modifiedMemberInfo = getOpenWorkPermitMainApplicant().memberInfo?.map((member) => ({
						...member,
						"productName": getOpenWorkPermitMainApplicant().name,
						"productId": getOpenWorkPermitMainApplicant().id,
					}));

					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}


			if (getCSQ().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCSQ().name)) {
				setResumeService(getCSQ().quantity + " " + getCSQ().name + " " + getCSQ().description);

				if (getCSQ().memberInfo) {
					const modifiedMemberInfo = getCSQ().memberInfo?.map((member) => ({
						...member,
						"productName": getCSQ().name,
						"productId": getCSQ().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getCSQGovernmentApplicationFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCSQGovernmentApplicationFee().name)) {
				setResumeService(getCSQGovernmentApplicationFee().quantity + " " + getCSQGovernmentApplicationFee().name + " " + getCSQGovernmentApplicationFee().description);

				if (getCSQGovernmentApplicationFee().memberInfo) {
					const modifiedMemberInfo = getCSQGovernmentApplicationFee().memberInfo?.map((member) => ({
						...member,
						"productName": getCSQGovernmentApplicationFee().name,
						"productId": getCSQGovernmentApplicationFee().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getCSQGovernmentApplicationFeeDependentChild().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCSQGovernmentApplicationFeeDependentChild().name)) {
				setResumeService(getCSQGovernmentApplicationFeeDependentChild().quantity + " " + getCSQGovernmentApplicationFeeDependentChild().name + " " + getCSQGovernmentApplicationFeeDependentChild().description);

				if (getCSQGovernmentApplicationFeeDependentChild().memberInfo) {
					const modifiedMemberInfo = getCSQGovernmentApplicationFeeDependentChild().memberInfo?.map((member) => ({
						...member,
						"productName": getCSQGovernmentApplicationFeeDependentChild().name,
						"productId": getCSQGovernmentApplicationFeeDependentChild().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().name)) {
				setResumeService(getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().quantity + " " + getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().name + " " + getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().description);

				if (getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().memberInfo) {
					const modifiedMemberInfo = getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().memberInfo?.map((member) => ({
						...member,
						"productName": getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().name,
						"productId": getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getSponsorshipApplicationOut().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getSponsorshipApplicationOut().name)) {
				setResumeService(getSponsorshipApplicationOut().quantity + " " + getSponsorshipApplicationOut().name + " " + getSponsorshipApplicationOut().description);

				if (getSponsorshipApplicationOut().memberInfo) {
					const modifiedMemberInfo = getSponsorshipApplicationOut().memberInfo?.map((member) => ({
						...member,
						"productName": getSponsorshipApplicationOut().name,
						"productId": getSponsorshipApplicationOut().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getExpressEntryExpressionOfInterestPhase1().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getExpressEntryExpressionOfInterestPhase1().name)) {
				setResumeService(getExpressEntryExpressionOfInterestPhase1().quantity + " " + getExpressEntryExpressionOfInterestPhase1().name + " " + getExpressEntryExpressionOfInterestPhase1().description);

				if (getExpressEntryExpressionOfInterestPhase1().memberInfo) {
					const modifiedMemberInfo = getExpressEntryExpressionOfInterestPhase1().memberInfo?.map((member) => ({
						...member,
						"productName": getExpressEntryExpressionOfInterestPhase1().name,
						"productId": getExpressEntryExpressionOfInterestPhase1().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getSpousePartnerExpressEntryExpressionOfInterestPhase1().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getSpousePartnerExpressEntryExpressionOfInterestPhase1().name)) {
				setResumeService(getSpousePartnerExpressEntryExpressionOfInterestPhase1().quantity + " " + getSpousePartnerExpressEntryExpressionOfInterestPhase1().name + " " + getSpousePartnerExpressEntryExpressionOfInterestPhase1().description);

				if (getSpousePartnerExpressEntryExpressionOfInterestPhase1().memberInfo) {
					const modifiedMemberInfo = getSpousePartnerExpressEntryExpressionOfInterestPhase1().memberInfo?.map((member) => ({
						...member,
						"productName": getSpousePartnerExpressEntryExpressionOfInterestPhase1().name,
						"productId": getSpousePartnerExpressEntryExpressionOfInterestPhase1().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().name)) {
				setResumeService(getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().quantity + " " + getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().name + " " + getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().description);

				if (getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().memberInfo) {
					const modifiedMemberInfo = getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().memberInfo?.map((member) => ({
						...member,
						"productName": getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().name,
						"productId": getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getStudyPermitExtentionON().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getStudyPermitExtentionON().name)) {
				setResumeService(getStudyPermitExtentionON().quantity + " " + getStudyPermitExtentionON().name + " " + getStudyPermitExtentionON().description);

				if (getStudyPermitExtentionON().memberInfo) {
					const modifiedMemberInfo = getStudyPermitExtentionON().memberInfo?.map((member) => ({
						...member,
						"productName": getStudyPermitExtentionON().name,
						"productId": getStudyPermitExtentionON().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getSpousePartnerOpenWorkPermitExtentionON().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getSpousePartnerOpenWorkPermitExtentionON().name)) {
				setResumeService(getSpousePartnerOpenWorkPermitExtentionON().quantity + " " + getSpousePartnerOpenWorkPermitExtentionON().name + " " + getSpousePartnerOpenWorkPermitExtentionON().description);

				if (getSpousePartnerOpenWorkPermitExtentionON().memberInfo) {
					const modifiedMemberInfo = getSpousePartnerOpenWorkPermitExtentionON().memberInfo?.map((member) => ({
						...member,
						"productName": getSpousePartnerOpenWorkPermitExtentionON().name,
						"productId": getSpousePartnerOpenWorkPermitExtentionON().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getTravelDocumentRenovationStickerVisaON().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getTravelDocumentRenovationStickerVisaON().name)) {
				setResumeService(getTravelDocumentRenovationStickerVisaON().quantity + " " + getTravelDocumentRenovationStickerVisaON().name + " " + getTravelDocumentRenovationStickerVisaON().description);

				if (getTravelDocumentRenovationStickerVisaON().memberInfo) {
					const modifiedMemberInfo = getTravelDocumentRenovationStickerVisaON().memberInfo?.map((member) => ({
						...member,
						"productName": getTravelDocumentRenovationStickerVisaON().name,
						"productId": getTravelDocumentRenovationStickerVisaON().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getDependantStudyPermitExtentionOverFiveDetails().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getDependantStudyPermitExtentionOverFiveDetails().name)) {
				setResumeService(getDependantStudyPermitExtentionOverFiveDetails().quantity + " " + getDependantStudyPermitExtentionOverFiveDetails().name + " " + getDependantStudyPermitExtentionOverFiveDetails().description);

				if (getDependantStudyPermitExtentionOverFiveDetails().memberInfo) {
					const modifiedMemberInfo = getDependantStudyPermitExtentionOverFiveDetails().memberInfo?.map((member) => ({
						...member,
						"productName": getDependantStudyPermitExtentionOverFiveDetails().name,
						"productId": getDependantStudyPermitExtentionOverFiveDetails().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getdependantStudyPermitExtentionLessFiveDetails().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getdependantStudyPermitExtentionLessFiveDetails().name)) {
				setResumeService(getdependantStudyPermitExtentionLessFiveDetails().quantity + " " + getdependantStudyPermitExtentionLessFiveDetails().name + " " + getdependantStudyPermitExtentionLessFiveDetails().description);

				if (getdependantStudyPermitExtentionLessFiveDetails().memberInfo) {
					const modifiedMemberInfo = getdependantStudyPermitExtentionLessFiveDetails().memberInfo?.map((member) => ({
						...member,
						"productName": getdependantStudyPermitExtentionLessFiveDetails().name,
						"productId": getdependantStudyPermitExtentionLessFiveDetails().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getPartnerOpenWorkPermitPOE().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPartnerOpenWorkPermitPOE().name)) {
				setResumeService(getPartnerOpenWorkPermitPOE().quantity + " " + getPartnerOpenWorkPermitPOE().name + " " + getPartnerOpenWorkPermitPOE().description);

				if (getPartnerOpenWorkPermitPOE().memberInfo) {
					const modifiedMemberInfo = getPartnerOpenWorkPermitPOE().memberInfo?.map((member) => ({
						...member,
						"productName": getPartnerOpenWorkPermitPOE().name,
						"productId": getPartnerOpenWorkPermitPOE().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getPGWPEON().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPGWPEON().name)) {
				setResumeService(getPGWPEON().quantity + " " + getPGWPEON().name + " " + getPGWPEON().description);

				if (getPGWPEON().memberInfo) {
					const modifiedMemberInfo = getPGWPEON().memberInfo?.map((member) => ({
						...member,
						"productName": getPGWPEON().name,
						"productId": getPGWPEON().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getPGWPPOE().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPGWPPOE().name)) {
				setResumeService(getPGWPPOE().quantity + " " + getPGWPPOE().name + " " + getPGWPPOE().description);

				if (getPGWPPOE().memberInfo) {
					const modifiedMemberInfo = getPGWPPOE().memberInfo?.map((member) => ({
						...member,
						"productName": getPGWPPOE().name,
						"productId": getPGWPPOE().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getPGWPEx().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPGWPEx().name)) {
				setResumeService(getPGWPEx().quantity + " " + getPGWPEx().name + " " + getPGWPEx().description);

				if (getPGWPEx().memberInfo) {
					const modifiedMemberInfo = getPGWPEx().memberInfo?.map((member) => ({
						...member,
						"productName": getPGWPEx().name,
						"productId": getPGWPEx().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getWorkPermitExemptONDetails().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getWorkPermitExemptONDetails().name)) {
				setResumeService(getWorkPermitExemptONDetails().quantity + " " + getWorkPermitExemptONDetails().name + " " + getWorkPermitExemptONDetails().description);

				if (getWorkPermitExemptONDetails().memberInfo) {
					const modifiedMemberInfo = getWorkPermitExemptONDetails().memberInfo?.map((member) => ({
						...member,
						"productName": getWorkPermitExemptONDetails().name,
						"productId": getWorkPermitExemptONDetails().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getWorkPermitExemptPOE().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getWorkPermitExemptPOE().name)) {
				setResumeService(getWorkPermitExemptPOE().quantity + " " + getWorkPermitExemptPOE().name + " " + getWorkPermitExemptPOE().description);

				if (getWorkPermitExemptPOE().memberInfo) {
					const modifiedMemberInfo = getWorkPermitExemptPOE().memberInfo?.map((member) => ({
						...member,
						"productName": getWorkPermitExemptPOE().name,
						"productId": getWorkPermitExemptPOE().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getWorkPermitPositiveON().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getWorkPermitPositiveON().name)) {
				setResumeService(getWorkPermitPositiveON().quantity + " " + getWorkPermitPositiveON().name + " " + getWorkPermitPositiveON().description);

				if (getWorkPermitPositiveON().memberInfo) {
					const modifiedMemberInfo = getWorkPermitPositiveON().memberInfo?.map((member) => ({
						...member,
						"productName": getWorkPermitPositiveON().name,
						"productId": getWorkPermitPositiveON().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getWorkPermitPositivePOE().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getWorkPermitPositivePOE().name)) {
				setResumeService(getWorkPermitPositivePOE().quantity + " " + getWorkPermitPositivePOE().name + " " + getWorkPermitPositivePOE().description);

				if (getWorkPermitPositivePOE().memberInfo) {
					const modifiedMemberInfo = getWorkPermitPositivePOE().memberInfo?.map((member) => ({
						...member,
						"productName": getWorkPermitPositivePOE().name,
						"productId": getWorkPermitPositivePOE().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getCoop().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCoop().name)) {
				setResumeService(getCoop().quantity + " " + getCoop().name + " " + getCoop().description);
				if (getCoop().memberInfo) {
					const modifiedMemberInfo = getCoop().memberInfo?.map((member) => ({
						...member,
						"productName": getCoop().name,
						"productId": getCoop().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getLMIAExExtentionPOE().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getLMIAExExtentionPOE().name)) {
				setResumeService(getLMIAExExtentionPOE().quantity + " " + getLMIAExExtentionPOE().name + " " + getLMIAExExtentionPOE().description);

				if (getLMIAExExtentionPOE().memberInfo) {
					const modifiedMemberInfo = getLMIAExExtentionPOE().memberInfo?.map((member) => ({
						...member,
						"productName": getLMIAExExtentionPOE().name,
						"productId": getLMIAExExtentionPOE().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getLMIAPosExtentionPOE().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getLMIAPosExtentionPOE().name)) {
				setResumeService(getLMIAPosExtentionPOE().quantity + " " + getLMIAPosExtentionPOE().name + " " + getLMIAPosExtentionPOE().description);

				if (getLMIAPosExtentionPOE().memberInfo) {
					const modifiedMemberInfo = getLMIAPosExtentionPOE().memberInfo?.map((member) => ({
						...member,
						"productName": getLMIAPosExtentionPOE().name,
						"productId": getLMIAPosExtentionPOE().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getQCsponsorship().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getQCsponsorship().name)) {
				setResumeService(getQCsponsorship().quantity + " " + getQCsponsorship().name + " " + getQCsponsorship().description);

				if (getQCsponsorship().memberInfo) {
					const modifiedMemberInfo = getQCsponsorship().memberInfo?.map((member) => ({
						...member,
						"productName": getQCsponsorship().name,
						"productId": getQCsponsorship().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getQCsponsorshipFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getQCsponsorshipFee().name)) {
				setResumeService(getQCsponsorshipFee().quantity + " " + getQCsponsorshipFee().name + " " + getQCsponsorshipFee().description);

				if (getQCsponsorshipFee().memberInfo) {
					const modifiedMemberInfo = getQCsponsorshipFee().memberInfo?.map((member) => ({
						...member,
						"productName": getQCsponsorshipFee().name,
						"productId": getQCsponsorshipFee().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getResidenceSponsorshipFeeParents().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getResidenceSponsorshipFeeParents().name)) {
				setResumeService(getResidenceSponsorshipFeeParents().quantity + " " + getResidenceSponsorshipFeeParents().name + " " + getResidenceSponsorshipFeeParents().description);

				if (getResidenceSponsorshipFeeParents().memberInfo) {
					const modifiedMemberInfo = getResidenceSponsorshipFeeParents().memberInfo?.map((member) => ({
						...member,
						"productName": getResidenceSponsorshipFeeParents().name,
						"productId": getResidenceSponsorshipFeeParents().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getQCAddMember().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getQCAddMember().name)) {
				setResumeService(getQCAddMember().quantity + " " + getQCAddMember().name + " " + getQCAddMember().description);

				if (getQCAddMember().memberInfo) {
					const modifiedMemberInfo = getQCAddMember().memberInfo?.map((member) => ({
						...member,
						"productName": getQCAddMember().name,
						"productId": getQCAddMember().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getLMIAExExtentionON().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getLMIAExExtentionON().name)) {
				setResumeService(getLMIAExExtentionON().quantity + " " + getLMIAExExtentionON().name + " " + getLMIAExExtentionON().description);

				if (getLMIAExExtentionON().memberInfo) {
					const modifiedMemberInfo = getLMIAExExtentionON().memberInfo?.map((member) => ({
						...member,
						"productName": getLMIAExExtentionON().name,
						"productId": getLMIAExExtentionON().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getWorkingHolidayFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getWorkingHolidayFee().name)) {
				setResumeService(getWorkingHolidayFee().quantity + " " + getWorkingHolidayFee().name + " " + getWorkingHolidayFee().description);

				if (getWorkingHolidayFee().memberInfo) {
					const modifiedMemberInfo = getWorkingHolidayFee().memberInfo?.map((member) => ({
						...member,
						"productName": getWorkingHolidayFee().name,
						"productId": getWorkingHolidayFee().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getInternationalExperienceCanadaWorkPermit().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getInternationalExperienceCanadaWorkPermit().name)) {
				setResumeService(getInternationalExperienceCanadaWorkPermit().quantity + " " + getInternationalExperienceCanadaWorkPermit().name + " " + getInternationalExperienceCanadaWorkPermit().description);

				if (getInternationalExperienceCanadaWorkPermit().memberInfo) {
					const modifiedMemberInfo = getInternationalExperienceCanadaWorkPermit().memberInfo?.map((member) => ({
						...member,
						"productName": getInternationalExperienceCanadaWorkPermit().name,
						"productId": getInternationalExperienceCanadaWorkPermit().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getdependantChildIndependentSponsor().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getdependantChildIndependentSponsor().name)) {
				setResumeService(getdependantChildIndependentSponsor().quantity + " " + getdependantChildIndependentSponsor().name + " " + getdependantChildIndependentSponsor().description);

				if (getdependantChildIndependentSponsor().memberInfo) {
					const modifiedMemberInfo = getdependantChildIndependentSponsor().memberInfo?.map((member) => ({
						...member,
						"productName": getdependantChildIndependentSponsor().name,
						"productId": getdependantChildIndependentSponsor().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getSpouseExEntry().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getSpouseExEntry().name)) {
				setResumeService(getSpouseExEntry().quantity + " " + getSpouseExEntry().name + " " + getSpouseExEntry().description);

				if (getSpouseExEntry().memberInfo) {
					const modifiedMemberInfo = getSpouseExEntry().memberInfo?.map((member) => ({
						...member,
						"productName": getSpouseExEntry().name,
						"productId": getSpouseExEntry().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getdependantExEntry().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getdependantExEntry().name)) {
				setResumeService(getdependantExEntry().quantity + " " + getdependantExEntry().name + " " + getdependantExEntry().description);

				if (getdependantExEntry().memberInfo) {
					const modifiedMemberInfo = getdependantExEntry().memberInfo?.map((member) => ({
						...member,
						"productName": getdependantExEntry().name,
						"productId": getdependantExEntry().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getExpressEntryPhaseTwo().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getExpressEntryPhaseTwo().name)) {
				setResumeService(getExpressEntryPhaseTwo().quantity + " " + getExpressEntryPhaseTwo().name + " " + getExpressEntryPhaseTwo().description);

				if (getExpressEntryPhaseTwo().memberInfo) {
					const modifiedMemberInfo = getExpressEntryPhaseTwo().memberInfo?.map((member) => ({
						...member,
						"productName": getExpressEntryPhaseTwo().name,
						"productId": getExpressEntryPhaseTwo().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getPReconomicPA().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPReconomicPA().name)) {
				setResumeService(getPReconomicPA().quantity + " " + getPReconomicPA().name + " " + getPReconomicPA().description);

				if (getPReconomicPA().memberInfo) {
					const modifiedMemberInfo = getPReconomicPA().memberInfo?.map((member) => ({
						...member,
						"productName": getPReconomicPA().name,
						"productId": getPReconomicPA().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getPReconomicSpouse().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPReconomicSpouse().name)) {
				setResumeService(getPReconomicSpouse().quantity + " " + getPReconomicSpouse().name + " " + getPReconomicSpouse().description);

				if (getPReconomicSpouse().memberInfo) {
					const modifiedMemberInfo = getPReconomicSpouse().memberInfo?.map((member) => ({
						...member,
						"productName": getPReconomicSpouse().name,
						"productId": getPReconomicSpouse().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}
			if (getPReconomicDepChild().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPReconomicDepChild().name)) {
				setResumeService(getPReconomicDepChild().quantity + " " + getPReconomicDepChild().name + " " + getPReconomicDepChild().description);

				if (getPReconomicDepChild().memberInfo) {
					const modifiedMemberInfo = getPReconomicDepChild().memberInfo?.map((member) => ({
						...member,
						"productName": getPReconomicDepChild().name,
						"productId": getPReconomicDepChild().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getRSWPphaseone().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getRSWPphaseone().name)) {
				setResumeService(getRSWPphaseone().quantity + " " + getRSWPphaseone().name + " " + getRSWPphaseone().description);

				if (getRSWPphaseone().memberInfo) {
					const modifiedMemberInfo = getRSWPphaseone().memberInfo?.map((member) => ({
						...member,
						"productName": getRSWPphaseone().name,
						"productId": getRSWPphaseone().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getspouseRSWPphaseone().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getspouseRSWPphaseone().name)) {
				setResumeService(getspouseRSWPphaseone().quantity + " " + getspouseRSWPphaseone().name + " " + getspouseRSWPphaseone().description);

				if (getspouseRSWPphaseone().memberInfo) {
					const modifiedMemberInfo = getspouseRSWPphaseone().memberInfo?.map((member) => ({
						...member,
						"productName": getspouseRSWPphaseone().name,
						"productId": getspouseRSWPphaseone().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getCSQDependantChild().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCSQDependantChild().name)) {
				setResumeService(getCSQDependantChild().quantity + " " + getCSQDependantChild().name + " " + getCSQDependantChild().description);

				if (getCSQDependantChild().memberInfo) {
					const modifiedMemberInfo = getCSQDependantChild().memberInfo?.map((member) => ({
						...member,
						"productName": getCSQDependantChild().name,
						"productId": getCSQDependantChild().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getCSQpr().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCSQpr().name)) {
				setResumeService(getCSQpr().quantity + " " + getCSQpr().name + " " + getCSQpr().description);

				if (getCSQpr().memberInfo) {
					const modifiedMemberInfo = getCSQpr().memberInfo?.map((member) => ({
						...member,
						"productName": getCSQpr().name,
						"productId": getCSQpr().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getdepedantotherPRFederal().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getdepedantotherPRFederal().name)) {
				setResumeService(getdepedantotherPRFederal().quantity + " " + getdepedantotherPRFederal().name + " " + getdepedantotherPRFederal().description);

				if (getdepedantotherPRFederal().memberInfo) {
					const modifiedMemberInfo = getdepedantotherPRFederal().memberInfo?.map((member) => ({
						...member,
						"productName": getdepedantotherPRFederal().name,
						"productId": getdepedantotherPRFederal().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getotherPRFederal().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getotherPRFederal().name)) {
				setResumeService(getotherPRFederal().quantity + " " + getotherPRFederal().name + " " + getotherPRFederal().description);

				if (getotherPRFederal().memberInfo) {
					const modifiedMemberInfo = getotherPRFederal().memberInfo?.map((member) => ({
						...member,
						"productName": getotherPRFederal().name,
						"productId": getotherPRFederal().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getspouseotherPRFederal().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getspouseotherPRFederal().name)) {
				setResumeService(getspouseotherPRFederal().quantity + " " + getspouseotherPRFederal().name + " " + getspouseotherPRFederal().description);

				if (getspouseotherPRFederal().memberInfo) {
					const modifiedMemberInfo = getspouseotherPRFederal().memberInfo?.map((member) => ({
						...member,
						"productName": getspouseotherPRFederal().name,
						"productId": getspouseotherPRFederal().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getCSQSpouseParnerFee().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCSQSpouseParnerFee().name)) {
				setResumeService(getCSQSpouseParnerFee().quantity + " " + getCSQSpouseParnerFee().name + " " + getCSQSpouseParnerFee().description);

				if (getCSQSpouseParnerFee().memberInfo) {
					const modifiedMemberInfo = getCSQSpouseParnerFee().memberInfo?.map((member) => ({
						...member,
						"productName": getCSQSpouseParnerFee().name,
						"productId": getCSQSpouseParnerFee().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}


			if (getCSQSpouseParner().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCSQSpouseParner().name)) {
				setResumeService(getCSQSpouseParner().quantity + " " + getCSQSpouseParner().name + " " + getCSQSpouseParner().description);

				if (getCSQSpouseParner().memberInfo) {
					const modifiedMemberInfo = getCSQSpouseParner().memberInfo?.map((member) => ({
						...member,
						"productName": getCSQSpouseParner().name,
						"productId": getCSQSpouseParner().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getDependentPN().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getDependentPN().name)) {
				setResumeService(getDependentPN().quantity + " " + getDependentPN().name + " " + getDependentPN().description);

				if (getDependentPN().memberInfo) {
					const modifiedMemberInfo = getDependentPN().memberInfo?.map((member) => ({
						...member,
						"productName": getDependentPN().name,
						"productId": getDependentPN().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getPN().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPN().name)) {
				setResumeService(getPN().quantity + " " + getPN().name + " " + getPN().description);

				if (getPN().memberInfo) {
					const modifiedMemberInfo = getPN().memberInfo?.map((member) => ({
						...member,
						"productName": getPN().name,
						"productId": getPN().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}


			if (getPNPProgram().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getPNPProgram().name)) {
				setResumeService(getPNPProgram().quantity + " " + getPNPProgram().name + " " + getPNPProgram().description);

				if (getPNPProgram().memberInfo) {
					const modifiedMemberInfo = getPNPProgram().memberInfo?.map((member) => ({
						...member,
						"productName": getPNPProgram().name,
						"productId": getPNPProgram().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getSpousePN().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getSpousePN().name)) {
				setResumeService(getSpousePN().quantity + " " + getSpousePN().name + " " + getSpousePN().description);

				if (getSpousePN().memberInfo) {
					const modifiedMemberInfo = getSpousePN().memberInfo?.map((member) => ({
						...member,
						"productName": getSpousePN().name,
						"productId": getSpousePN().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getCSQApplicationFeeMainApplicant().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getCSQApplicationFeeMainApplicant().name)) {
				setResumeService(getCSQApplicationFeeMainApplicant().quantity + " " + getCSQApplicationFeeMainApplicant().name + " " + getCSQApplicationFeeMainApplicant().description);

				if (getCSQApplicationFeeMainApplicant().memberInfo) {
					const modifiedMemberInfo = getCSQApplicationFeeMainApplicant().memberInfo?.map((member) => ({
						...member,
						"productName": getCSQApplicationFeeMainApplicant().name,
						"productId": getCSQApplicationFeeMainApplicant().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getEmploymentletter().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getEmploymentletter().name)) {
				setResumeService(getEmploymentletter().quantity + " " + getEmploymentletter().name + " " + getEmploymentletter().description);

				if (getEmploymentletter().memberInfo) {
					const modifiedMemberInfo = getEmploymentletter().memberInfo?.map((member) => ({
						...member,
						"productName": getEmploymentletter().name,
						"productId": getEmploymentletter().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}

			if (getOpenWorkPermitVulnerable().quantity > 0 &&
			!getResumeService().split(',').map(service => service.trim()).includes(getOpenWorkPermitVulnerable().name)) {
				setResumeService(getOpenWorkPermitVulnerable().quantity + " " + getOpenWorkPermitVulnerable().name + " " + getOpenWorkPermitVulnerable().description);

				if (getOpenWorkPermitVulnerable().memberInfo) {
					const modifiedMemberInfo = getOpenWorkPermitVulnerable().memberInfo?.map((member) => ({
						...member,
						"productName": getOpenWorkPermitVulnerable().name,
						"productId": getOpenWorkPermitVulnerable().id,
					}));
					setProductAplicantService(modifiedMemberInfo);
					addingAplicantProductInformation(modifiedMemberInfo)
				}
			}


			setApplicantInfoMatterFromResumeComponent(JSON.stringify(applicantProductInformation))
			setTriggerUseEffect(1)
		}

	}
	const jsonTotal: string[] = []
	jsonTotal.length = 0
	familyMembers.map((member) => {

		jsonTotal.push(JSON.stringify(member.answers))

	})
	const jsonArray: object[] = [];
	for (const jsonStr of jsonTotal) {
		const parsedJson = JSON.parse(jsonStr);
		jsonArray.push(...parsedJson);
	}

	setAnswers(JSON.stringify(jsonArray))

	useEffect(() => {
		setMatterFromResumeComponent(resumeService)
	}, [triggerUseEffect])




	return (
		<>   {/* <TableCell> Prodsucstos comprados </TableCell>
			<TableCell> {getResumeService()}</TableCell>
			<TableCell>{JSON.stringify(getProductAplicantsService())}</TableCell>
			<TableCell>{JSON.stringify(productAplicantsService)}</TableCell> */}
		</>
	)
}

export default observer(Resumen)
